import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
let MapSelectbox = (_temp = class MapSelectbox extends Vue {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isOpen", false);

    _defineProperty(this, "selectedOption", null);

    _defineProperty(this, "options", void 0);

    _defineProperty(this, "selectedDefault", void 0);

    _defineProperty(this, "onSelect", void 0);
  }

  onSelectedDefaultChange(val) {
    // Find option related to default
    const option = this.options.find(({
      label
    }) => label === val) || null;
    this.selectedOption = option || {
      id: -1,
      label: val
    };
  }

  onOptionsChange() {
    if (this.selectedDefault) {
      this.selectedOption = {
        id: -1,
        label: this.selectedDefault
      };
    }
  }

  clickHandler(event) {
    const {
      target
    } = event;

    if (!this.$el.contains(target)) {
      this.isOpen = false;
    }
  }

  toggleOption() {
    this.isOpen = !this.isOpen;
  }

  handleSelection(option) {
    this.selectedOption = option;

    if (this.onSelect) {
      this.onSelect(option);
    }
  }

  mounted() {
    this.selectedDefault && this.onSelectedDefaultChange(this.selectedDefault);
    document.addEventListener('click', this.clickHandler);
  }

  beforeUnmount() {
    document.removeEventListener('click', this.clickHandler);
  }

}, _temp);

__decorate([Prop()], MapSelectbox.prototype, "options", void 0);

__decorate([Prop()], MapSelectbox.prototype, "selectedDefault", void 0);

__decorate([Prop()], MapSelectbox.prototype, "onSelect", void 0);

__decorate([Watch('selectedDefault')], MapSelectbox.prototype, "onSelectedDefaultChange", null);

__decorate([Watch('options')], MapSelectbox.prototype, "onOptionsChange", null);

MapSelectbox = __decorate([Component], MapSelectbox);
export default MapSelectbox;