import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Hero
 */

import { Component } from 'vue-property-decorator';
import gsap from 'gsap';
import DefaultComponent from '@script/mixins/DefaultComponent';
import SeeMore from '@script/components/SeeMore/SeeMore.vue';
import { EVENTS } from '@script/config/constant';
let Hero = (_temp = class Hero extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "heroTimeline", void 0);
  }

  /**
   *
   */
  mounted() {
    this.initHeroTimeline();
    this.attachEventsHandlers();
    this.addSpaceSliderIfBanner();
  }

  attachEventsHandlers() {
    this.$events.$on(EVENTS.WINDOW_RESIZE, this.onWindowResize);
  }

  initHeroTimeline() {
    let xLeft = -60;
    let xRight = 60;
    this.heroTimeline = gsap.timeline({
      paused: true,
      yoyo: true
    });

    if (window.innerWidth < 768 && window.innerWidth >= 640) {
      xLeft = -40;
      xRight = 40;
    } else if (window.innerWidth < 640) {
      xLeft = -30;
      xRight = 30;
    }

    if (this.$el.querySelector('.picto')) {
      this.heroTimeline.from(this.$el.querySelector('.picto'), {
        autoAlpha: 0,
        x: -100,
        duration: 1,
        ease: 'power2.out'
      }).to(this.$el.querySelector('.text-left span'), {
        x: xLeft,
        duration: 1,
        ease: 'power3.out'
      }, 1).to(this.$el.querySelector('.text-right span'), {
        x: xRight,
        duration: 1,
        ease: 'power3.out'
      }, 0.5).play();
    }

    if (this.$el.querySelector('.text-single span')) {
      this.heroTimeline.fromTo(this.$el.querySelector('.text-single span'), {
        y: -100,
        opacity: 0
      }, {
        y: 0,
        opacity: 1,
        duration: 1
      }).play();
    }
  }

  onWindowResize() {
    this.addSpaceSliderIfBanner();

    if (this.heroTimeline) {
      this.heroTimeline.clear().kill();
    }

    gsap.set([this.$el.querySelector('.picto'), this.$el.querySelector('.text-left span'), this.$el.querySelector('.text-right span')], {
      clearProps: 'all'
    });
    gsap.set(this.$el.querySelector('.text-single span'), {
      y: -100,
      opacity: 0
    });
    this.initHeroTimeline();
  }

  addSpaceSliderIfBanner() {
    if (window.matchMedia('(max-width: 768px)').matches) {
      const homeBanner = document.querySelector('body.home .header-banner');
      const heroSlideText = this.$el.querySelectorAll('.hero-slide-text');
      console.log('homeBanner', homeBanner);
      console.log('heroSlideText', heroSlideText);

      if (homeBanner instanceof HTMLDivElement && heroSlideText.length) {
        heroSlideText.forEach(slideText => {
          slideText.style.top = '27%';
        });
      }
    }
  }

}, _temp);
Hero = __decorate([Component({
  components: {
    SeeMore
  }
})], Hero);
export default Hero;