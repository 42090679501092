import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Accordion standard
 */

import { Component } from 'vue-property-decorator';
import AccordionComponent from '@script/mixins/AccordionComponent';
let AccordionStandard = (_temp = class AccordionStandard extends AccordionComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "options", {
      multiple: true
    });
  }

}, _temp);
AccordionStandard = __decorate([Component], AccordionStandard);
export default AccordionStandard;