import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
import LocationsListCountries from '@script/components/BlockLocations/LocationsListCountries.vue';
import Events, { LOCATIONS_EVENTS } from '@script/components/BlockLocations/BlockLocationsEvents';
import LocationsList from '@script/components/BlockLocations/LocationsList.vue';
import LocationView from '@script/components/BlockLocations/LocationView.vue';
let ContentWrapper = (_temp = class ContentWrapper extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "currentPage", 0);

    _defineProperty(this, "currentLocations", []);

    _defineProperty(this, "selectedLocation", null);

    _defineProperty(this, "countries", void 0);

    _defineProperty(this, "allLocations", void 0);
  }

  onSelectCountry(country) {
    this.currentPage = 1;

    if (this.allLocations && this.allLocations.length) {
      this.currentLocations = this.allLocations.filter(location => location.country === country.label);
    }
  }

  onSelectLocation(loc) {
    this.currentPage = 2;
    this.selectedLocation = loc;
  }

  onPageChanged(pageNumber) {
    this.currentPage = pageNumber;
  }

  mounted() {
    Events.$on(LOCATIONS_EVENTS.ON_SELECT_COUNTRY, this.onSelectCountry);
    Events.$on(LOCATIONS_EVENTS.ON_SELECT_LOCATION, this.onSelectLocation);
    Events.$on(LOCATIONS_EVENTS.ON_CHANGE_PAGE, this.onPageChanged);
  }

}, _temp);

__decorate([Prop()], ContentWrapper.prototype, "countries", void 0);

__decorate([Prop()], ContentWrapper.prototype, "allLocations", void 0);

ContentWrapper = __decorate([Component({
  components: {
    LocationsListCountries,
    LocationsList,
    LocationView
  }
})], ContentWrapper);
export default ContentWrapper;