import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Slider standard
 */

import { Component } from 'vue-property-decorator';
import SliderComponent from '@script/mixins/SliderComponent';
let SliderMobile = (_temp = class SliderMobile extends SliderComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "options", {
      slidesPerView: 'auto'
    });
  }

  /**
   * Mounted
   */
  mounted() {
    this.destroySlider();
    this.attachEventHandlers();
  }
  /**
   * Attach event handlers
   */


  attachEventHandlers() {
    window.addEventListener('resize', this.destroySlider);
  }
  /**
   * Destroy slider
   */


  destroySlider() {
    if (window.innerWidth > 992) {
      this.sliderComponent.disable();
    } else {
      this.sliderComponent.enable();
    }
  }

}, _temp);
SliderMobile = __decorate([Component], SliderMobile);
export default SliderMobile;