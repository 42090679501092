import "core-js/modules/web.dom-collections.iterator";
import "core-js/modules/web.url";
import 'whatwg-fetch';
const AjaxUtils = {
  postData(url = '', data = {}) {
    // Default options are marked with *
    const response = window.fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      },
      credentials: 'same-origin',
      body: new URLSearchParams(data)
    });
    return response.then(r => r.json());
  }

};
export default AjaxUtils;