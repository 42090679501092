import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
import Events, { LOCATIONS_EVENTS } from '@script/components/BlockLocations/BlockLocationsEvents';
import MapSelectbox from '@script/components/BlockLocations/MapSelectbox.vue';
let LocationsList = (_temp = class LocationsList extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "currentLocations", []);

    _defineProperty(this, "defaultSelectboxLabel", Vue.$mapTranslations.filterByActivities);

    _defineProperty(this, "title", Vue.$mapTranslations.locations);

    _defineProperty(this, "contactUsLabel", Vue.$mapTranslations.seeMore);

    _defineProperty(this, "selectboxOptions", []);

    _defineProperty(this, "selectedOption", null);

    _defineProperty(this, "locations", void 0);
  }

  onFilterChange(val) {
    if (val !== Vue.$mapTranslations.filterByActivities) {
      this.currentLocations = this.locations.filter(({
        activities
      }) => {
        const activitiesCp = activities.map(activity => activity.toLowerCase());
        return activitiesCp.includes(val.toLowerCase());
      });
    } else {
      this.currentLocations = this.locations;
    }

    this.defaultSelectboxLabel = val; // Dispatch

    Events.dispatchEvent(LOCATIONS_EVENTS.ON_LOCATIONS_LIST_UPDATE, this.currentLocations);
  }

  onLocationsChanged(val) {
    this.buildActivities(val);
    this.onFilterChange(Vue.$mapTranslations.filterByActivities);
  }

  buildActivities(locs) {
    if (!locs.length) {
      return;
    }

    let counter = 0; // Merge all activities

    const allActivities = locs.reduce((accu, item) => {
      const prev = [...accu];

      if (item.activities) {
        return [...prev, ...item.activities];
      }

      return prev;
    }, locs[0].activities && [...locs[0].activities] || []); // Build

    const options = allActivities.reduce((accu, item) => {
      const find = accu.find(({
        label
      }) => label === item);

      if (!find) {
        counter++;
        accu.push({
          id: counter,
          label: item
        });
      }

      return accu;
    }, [{
      id: counter,
      label: allActivities[counter]
    }]).filter(opt => Boolean(opt.label));
    this.selectboxOptions = options.length ? [{
      id: -1,
      label: Vue.$mapTranslations.filterByActivities
    }, ...options] : [];
  }

  onSelectActivity(option) {
    Events.dispatchEvent(LOCATIONS_EVENTS.ON_ACTIVITY_FILTER_CHANGE, option.label);
  }

  onSelectLocation(location) {
    Events.dispatchEvent(LOCATIONS_EVENTS.ON_SELECT_LOCATION, location);
  }

  mounted() {
    // Build selectbox options
    this.buildActivities(this.locations); // Apply filter

    this.onFilterChange(this.defaultSelectboxLabel); // Listen filter change

    Events.$on(LOCATIONS_EVENTS.ON_ACTIVITY_FILTER_CHANGE, this.onFilterChange);
  }

}, _temp);

__decorate([Prop()], LocationsList.prototype, "locations", void 0);

__decorate([Watch('locations')], LocationsList.prototype, "onLocationsChanged", null);

LocationsList = __decorate([Component({
  components: {
    MapSelectbox
  }
})], LocationsList);
export default LocationsList;