import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Slider standard
 */

import { Component } from 'vue-property-decorator';
import SliderComponent from '@script/mixins/SliderComponent';
let SliderOffers = (_temp = class SliderOffers extends SliderComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "options", {
      slidesPerView: 'auto',
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      }
    });
  }

  /**
   * Attach event
   */
  attachEventsHandlers() {
    let active = 0;
    const TITLES = this.$el.querySelectorAll('.swiper-title .link');

    if (TITLES.length) {
      TITLES.item(0).classList.add('active');
      TITLES.forEach((link, index) => {
        link.addEventListener('click', event => {
          event.preventDefault();
          TITLES.item(active).classList.remove('active');
          link.classList.add('active');
          active = index;
          this.slideTo(index);
        });
      });
    }
  }

}, _temp);
SliderOffers = __decorate([Component], SliderOffers);
export default SliderOffers;