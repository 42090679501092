import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Contact form
 */

import { Component, Watch } from 'vue-property-decorator';
import gsap from 'gsap';
import DefaultComponent from '@script/mixins/DefaultComponent'; // import { getMaxHeight } from '@script/utils/utils'

let NewContactForm = (_temp = class NewContactForm extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "active", -1);

    _defineProperty(this, "confirmActive", false);

    _defineProperty(this, "formToggler", void 0);

    _defineProperty(this, "forms", void 0);

    _defineProperty(this, "title", void 0);

    _defineProperty(this, "lastActiveForm", -1);

    _defineProperty(this, "allFormBlock", void 0);

    _defineProperty(this, "resizeObserver", new ResizeObserver(elemts => {
      for (const elem of elemts) {
        const contactForm = document.querySelector('.new-contact-form');
        const ListFormToggler = document.querySelector('.list-form-toggler'); // console.log('test 00', NewContactForm, '---------------', this.getConfirmActive())

        this.isListOneCol();

        if (!this.getConfirmActive() && !this.isListOneCol() && contactForm instanceof HTMLElement && ListFormToggler) {
          contactForm.style.height = `${elem.borderBoxSize[0].blockSize + ListFormToggler.clientHeight}px`;
        } else {
          if (contactForm instanceof HTMLElement) {
            // console.log('test 2')
            if (this.getConfirmActive() && !this.isListOneCol() && ListFormToggler) {
              contactForm.style.height = `${300 + ListFormToggler.clientHeight}px`;
            } else {
              contactForm.style.height = '';
            }
          }
        }
      }
    }));
  }

  getConfirmActive() {
    return this.confirmActive;
  }
  /**
   * Component mounted
   */


  mounted() {
    this.allFormBlock = this.$el.querySelectorAll('.form-block');
    this.attachEventsHandlers();
    this.initNewContactForms();
    this.resizeObserver;
  }

  contactFormHeight() {
    const contactForm = this.$el;
    const ListFormToggler = contactForm.querySelector('.list-form-toggler');
    const listFormTogglerLi = this.$el.querySelectorAll('.list-form-toggler li');
    const activeForm = this.forms[this.active];
    const confirmationMsg = this.active !== -1 ? listFormTogglerLi[this.active].querySelector('.confirmation-message') : null;
    let confirmMsgHeight;

    if (this.active && activeForm instanceof HTMLElement && ListFormToggler instanceof HTMLElement && contactForm instanceof HTMLElement) {
      contactForm.style.height = `${activeForm.clientHeight + ListFormToggler.clientHeight + 30}px`;
    }

    if (this.getConfirmActive() && contactForm instanceof HTMLElement && ListFormToggler instanceof HTMLElement && confirmationMsg instanceof HTMLElement) {
      confirmMsgHeight = confirmationMsg.clientHeight < 300 ? 300 : confirmationMsg.clientHeight;
      contactForm.style.height = `${confirmMsgHeight + ListFormToggler.clientHeight + 30}px`;
    }
  }

  isListOneCol() {
    if (window.innerWidth >= 640) {
      return false;
    } else {
      return true;
    }
  }
  /**
   * Attach event handler
   */


  attachEventsHandlers() {
    const contactForm = this.$el;
    this.formToggler = contactForm.querySelectorAll('.list-form-toggler li');
    this.forms = contactForm.querySelectorAll('.form');
    this.title = contactForm.querySelector('.subtitle');
    /**
     * Contact form sumitted
     */

    document.addEventListener('wpcf7submit', () => {
      const formList = document.querySelectorAll('.wpcf7-form');
      formList.forEach(element => {
        element.classList.remove('doing-submission'); // Resize contact form height

        this.contactFormHeight();
      });
    }, false);
    /**
     * Contact form submitted
     */

    document.addEventListener('wpcf7mailsent', this.confirmationTimeline, false);
    this.getHash();
  }
  /**
   * Set active form
   * @param index
   */


  set(index) {
    this.removeClassActive();

    if (this.active === index) {
      this.active = -1;
    } else {
      this.active = index;
      this.allFormBlock[index].classList.add('active');
    }
  }
  /**
   * Remove class 'active'
   */


  removeClassActive() {
    for (const formBlock of this.allFormBlock) {
      formBlock.classList.remove('active');
      formBlock.style.height = '';
    }
  }

  showFormTimeline(incoming) {
    const contactForm = this.$el;
    const listFormTogglerLi = this.$el.querySelectorAll('.list-form-toggler li');
    const ListFormToggler = contactForm.querySelector('.list-form-toggler');
    const activeForm = this.forms[incoming];
    const confirmationMsg = this.lastActiveForm !== -1 ? listFormTogglerLi[this.lastActiveForm].querySelector('.confirmation-message') : null;
    const timeline = gsap.timeline(); // confirm Message

    if (this.lastActiveForm === incoming || this.getConfirmActive() && confirmationMsg instanceof HTMLDivElement) {
      this.confirmActive = false;
      timeline.to(confirmationMsg, {
        yPercent: 100,
        autoAlpha: 0,
        duration: 0.5,
        ease: 'power2.out',
        onComplete: () => {
          confirmationMsg.style.height = '';
          confirmationMsg.classList.add('hidden');
        }
      });
    } // console.log(incoming, this.active, this.lastActiveForm)
    // console.log('test is on col', !this.isListOneCol)
    // if last active form index = -1


    if (incoming !== -1 && activeForm instanceof HTMLElement && contactForm instanceof HTMLElement && ListFormToggler instanceof HTMLElement) {
      // open and set the current form
      if (this.lastActiveForm !== -1) {
        timeline.to(this.forms[this.lastActiveForm], {
          yPercent: 100,
          opacity: 0,
          duration: 0.2,
          ease: 'power2.in',
          onComplete: () => {
            // to do fermer tout les formulaire
            this.forms[this.lastActiveForm].style.height = ''; // console.log('unobserve')

            if (this.forms[this.lastActiveForm]) {
              this.resizeObserver.unobserve(this.forms[this.lastActiveForm]);
            }
          }
        });
      }

      timeline.set(activeForm, {
        height: 'auto',
        yPercent: 100,
        opacity: 0,
        duration: 0.1,
        ease: 'power2.in',
        onComplete: () => {
          if (!this.isListOneCol) {
            setTimeout(function () {
              contactForm.style.height = `${activeForm.clientHeight + ListFormToggler.clientHeight + 30}px`;
            }, 0.2);
          }
        }
      });
      timeline.to(activeForm, {
        height: 'auto',
        yPercent: 0,
        opacity: 1,
        visibility: 'visible',
        duration: 0.4,
        delay: 0.2,
        ease: 'power2.in',
        onStart: () => {
          if (!this.isListOneCol()) {
            contactForm.style.height = `${activeForm.clientHeight + ListFormToggler.clientHeight + 30}px`;
          }
        },
        onComplete: () => {
          let scrollEl;
          this.lastActiveForm = incoming !== this.lastActiveForm ? incoming : -1;

          if (!this.isListOneCol()) {
            scrollEl = document.querySelector('.formScroll');
            contactForm.style.height = `${activeForm.clientHeight + ListFormToggler.clientHeight + 30}px`;
          } else {
            scrollEl = this.forms[incoming];
          } // console.log('scoll active', scrollEl)


          if (scrollEl instanceof HTMLElement) {
            var _document$querySelect;

            gsap.to(window, {
              duration: 0.3,
              scrollTo: {
                y: scrollEl,
                offsetY: (_document$querySelect = document.querySelector('.main-header')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.clientHeight
              }
            });
          } // Observe


          this.resizeObserver.observe(activeForm);
        }
      });
    }
    /**
     * all form is close
     */
    // console.log('test close', this.lastActiveForm, incoming, contactForm instanceof HTMLElement)


    if (incoming === -1 && contactForm instanceof HTMLElement) {
      // console.log('lastActiveForm === incomming')
      // Animate current form close
      timeline.to(this.forms[this.lastActiveForm], {
        yPercent: 100,
        opacity: 0,
        duration: 0.2,
        ease: 'power2.in',
        onComplete: () => {
          this.forms[this.lastActiveForm].style.height = '';
          this.lastActiveForm = incoming !== this.lastActiveForm ? incoming : -1;
          this.resizeObserver.unobserve(this.forms[this.lastActiveForm]);
        }
      });
      timeline.to(contactForm, {
        height: 'auto',
        duration: 0.4,
        delay: 0.2,
        ease: 'power2.in',
        onComplete: () => {
          contactForm.style.height = '';
        }
      });
    }
  }

  confirmationTimeline() {
    const contactForm = this.$el;
    const listForm = this.$el.querySelector('.form');
    const ListFormToggler = this.$el.querySelector('.list-form-toggler');
    const listFormToggler = this.$el.querySelectorAll('.list-form-toggler li');
    const activeForm = this.forms[this.active];
    const confirmationMsg = listFormToggler[this.active].querySelector('.confirmation-message');
    const timeline = gsap.timeline({
      paused: true
    });
    this.confirmActive = true;
    timeline.addLabel('start'); // console.log('confirm timeline')

    if (activeForm instanceof HTMLElement && listForm instanceof HTMLElement) {
      timeline.to(activeForm, {
        yPercent: 100,
        autoAlpha: 0,
        duration: 0.4,
        ease: 'power2.out',
        onComplete: () => {
          activeForm.style.height = '';
        }
      }, 'start');
    }

    if (confirmationMsg instanceof HTMLElement) {
      timeline.to(confirmationMsg, {
        y: 0,
        autoAlpha: 1,
        duration: 0.6,
        ease: 'power2.easeInOut',
        onStart: () => {
          confirmationMsg.classList.remove('hidden');
        },
        onComplete: () => {
          let scrollEl;

          if (!this.isListOneCol() && ListFormToggler instanceof HTMLElement && contactForm instanceof HTMLElement) {
            const confirmMsgHeight = confirmationMsg.clientHeight < 300 ? 300 : confirmationMsg.clientHeight;
            contactForm.style.height = `${confirmMsgHeight + ListFormToggler.clientHeight + 30}px`; // console.log('test height is list on cold. form close', contactForm.style.height)
          }

          if (!this.isListOneCol()) {
            this.contactFormHeight;
            scrollEl = document.querySelector('.formScrollConfirmation');
          } else {
            scrollEl = activeForm;
          } // console.log('active form', scrollEl, this.isListOneCol())


          if (scrollEl instanceof HTMLElement) {
            var _document$querySelect2;

            gsap.to(window, {
              duration: 0.3,
              scrollTo: {
                y: scrollEl,
                offsetY: (_document$querySelect2 = document.querySelector('.main-header')) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.clientHeight
              }
            });
          }
        }
      });
    }

    timeline.play();
  }

  getHash() {
    const form = this.$el.getAttribute('data-anchor');
    const listHash = form === null || form === void 0 ? void 0 : form.split(',');
    const hash = window.location.hash; // console.log('test hash', hash, listHash?.indexOf(hash))

    if (hash && listHash) {
      const index = listHash === null || listHash === void 0 ? void 0 : listHash.indexOf(hash); // console.log('test hash', hash, listHash?.indexOf(hash))

      this.set(index);
    }
  }
  /**
   * Initialize contact forms
   * @method initNewContactForms
   * @return {Void}
   */


  initNewContactForms() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const {
      wpcf7
    } = window;

    if (wpcf7) {
      const formList = document.querySelectorAll('.wpcf7-form');

      if (formList.length) {
        formList.forEach(element => {
          const button = element === null || element === void 0 ? void 0 : element.querySelector('.cta'); // const files = element.querySelectorAll('.file-placeholder')

          /**
           * Initialize contact forms
           */

          wpcf7.init(element);
          /**
           * Submit button hook
           */

          button === null || button === void 0 ? void 0 : button.addEventListener('click', () => {
            element.classList.add('doing-submission');
          });
        });
      }
    }
  }

}, _temp);

__decorate([Watch('active')], NewContactForm.prototype, "showFormTimeline", null);

NewContactForm = __decorate([Component], NewContactForm);
export default NewContactForm;