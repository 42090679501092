import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
import { EVENTS } from '@script/config/constant';
let Selectbox = (_temp = class Selectbox extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "selected", 0);

    _defineProperty(this, "isOpen", false);

    _defineProperty(this, "selectedOption", void 0);

    _defineProperty(this, "options", void 0);

    _defineProperty(this, "selectedDefault", void 0);

    _defineProperty(this, "enableEnterKey", void 0);
  }

  /**
   * Create
   */
  created() {
    this.selectedOption = this.selectedDefault;
  }
  /**
   * Mounted
   */


  mounted() {
    console.log('enableEnterKey --> ', this.enableEnterKey);
    this.attachEventHandler();
  }
  /**
   * Attach event handler
   */


  attachEventHandler() {
    document.addEventListener('click', this.clickHandler);
    this.$events.$on(EVENTS.SELECT_UPDATE_OPTION, this.updateSelectedOption);
  }
  /**
   * Update option
   */


  updateOption(index) {
    this.selected = index;
    this.$events.$emit(EVENTS.SELECT_UPDATE_OPTION, this.selected, this);
  }
  /**
   * On select option
   * */


  onSelectOption(index) {
    this.updateOption(index);
    this.listenKeyDown(true);
  }
  /**
   * Click event handler
   * @param event
   */


  clickHandler(event) {
    const {
      target
    } = event;
    const {
      $el
    } = this;

    if (!$el.contains(target)) {
      this.isOpen = false;
      this.listenKeyDown(false);
    }
  }
  /**
   * Enter key handler
   * @param evt
   * */


  enterKeyHandler(evt) {
    if ((evt === null || evt === void 0 ? void 0 : evt.code) === 'Enter') {
      this.$events.$emit(EVENTS.SELECT_KEY_ENTER_VALIDATION, this.selected, this);
    }
  }
  /**
   * Add/remove key enter listener according to focus state
   * @param hasFocus
   * */


  listenKeyDown(hasFocus) {
    if (this.enableEnterKey) {
      if (hasFocus) {
        document.addEventListener('keydown', this.enterKeyHandler);
      } else {
        document.removeEventListener('keydown', this.enterKeyHandler);
      }
    }
  }
  /**
   * Update selected option value
   */


  updateSelectedOption(index, _this) {
    if (this.$el.id === _this.$el.id) {
      this.selectedOption = this.options[index];
    }
  }
  /**
   * Toggle select menu
   */


  toggleOption() {
    this.isOpen = !this.isOpen;
  }

}, _temp);

__decorate([Prop()], Selectbox.prototype, "options", void 0);

__decorate([Prop()], Selectbox.prototype, "selectedDefault", void 0);

__decorate([Prop()], Selectbox.prototype, "enableEnterKey", void 0);

Selectbox = __decorate([Component], Selectbox);
export default Selectbox;