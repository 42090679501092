import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Slider standard
 */

import { Component } from 'vue-property-decorator';
import SliderMobile from '@script/components/Slider/SliderMobile';
let SliderTeam = (_temp = class SliderTeam extends SliderMobile {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "options", {
      slidesPerView: 1,
      breakpoints: {
        560: {
          slidesPerView: 2
        }
      }
    });
  }

  /**
   * Destroy slider
   */
  destroySlider() {
    const sliderWrapper = this.$el.querySelector('.swiper-wrapper');
    const sliderItems = sliderWrapper.querySelectorAll('.swiper-wrapper .swiper-slide');

    if (window.innerWidth > 768) {
      sliderWrapper.removeAttribute('style');

      for (const item of sliderItems) {
        item.removeAttribute('style');

        if (item.classList.contains('swiper-slide-active')) {
          item.classList.remove('swiper-slide-active');
        }
      }

      this.sliderComponent.disable();
    } else {
      this.sliderComponent.enable();
    }
  }

}, _temp);
SliderTeam = __decorate([Component], SliderTeam);
export default SliderTeam;