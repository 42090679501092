import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";
export default class Newsletter {
  static init() {
    Newsletter.$buttonOpen = document.querySelector('.push-newsletter-button');
    Newsletter.$popup = document.querySelector('.popup-newsletter');
    Newsletter.$body = document.querySelector('body');

    if (Newsletter.$popup instanceof HTMLElement) {
      Newsletter.$buttonClose = Newsletter.$popup.querySelector('span.close');

      if (Newsletter.$buttonOpen) {
        Newsletter.$buttonOpen.classList.add('open');
        Newsletter.$buttonOpen.addEventListener('click', () => {
          var _Newsletter$$popup;

          (_Newsletter$$popup = Newsletter.$popup) === null || _Newsletter$$popup === void 0 ? void 0 : _Newsletter$$popup.classList.add('open');
          Newsletter.$body.classList.add('open-popup-newsletter');
        });
      }

      if (Newsletter.$buttonClose) {
        Newsletter.$buttonClose.addEventListener('click', () => {
          var _Newsletter$$popup2;

          (_Newsletter$$popup2 = Newsletter.$popup) === null || _Newsletter$$popup2 === void 0 ? void 0 : _Newsletter$$popup2.classList.remove('open');
          Newsletter.$body.classList.remove('open-popup-newsletter');
        });
      }
    }
  }

}

_defineProperty(Newsletter, "$buttonOpen", void 0);

_defineProperty(Newsletter, "$buttonClose", void 0);

_defineProperty(Newsletter, "$popup", void 0);

_defineProperty(Newsletter, "$body", void 0);