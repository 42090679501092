import { __decorate } from "tslib";
/**
 * Header
 */

import { Component } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
import { EVENTS } from '@script/config/constant';
let Dropdown = class Dropdown extends DefaultComponent {
  /**
   * Mounted
   */
  mounted() {
    this.attachEventHandler();
  }
  /**
   * Attach event handler
   */


  attachEventHandler() {
    const toggler = this.$el.querySelector('.dropdown-toggler');
    const dropdown = this.$el.querySelector('.dropdown');
    const options = dropdown === null || dropdown === void 0 ? void 0 : dropdown.querySelectorAll('li > a');
    const selected = toggler === null || toggler === void 0 ? void 0 : toggler.querySelector('.selected');
    const placeholder = toggler === null || toggler === void 0 ? void 0 : toggler.querySelector('.placeholder');
    options === null || options === void 0 ? void 0 : options.forEach(option => {
      option.addEventListener('click', event => {
        const {
          target
        } = event;

        if (selected instanceof HTMLElement) {
          selected.innerText = target.innerText;
          placeholder === null || placeholder === void 0 ? void 0 : placeholder.setAttribute('hidden', 'true');
          this.$events.$emit(EVENTS.DROPDOWN_UPDATE_OPTION, {
            component: this.$el,
            value: target.innerText
          });
        }
      });
    });
  }

};
Dropdown = __decorate([Component], Dropdown);
export default Dropdown;