var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map" },
    [
      _c("div", { staticClass: "btn-zoom-wrapper" }, [
        _c(
          "button",
          {
            staticClass: "btn-zoom",
            on: {
              click: function($event) {
                return _vm.onZoom(1)
              }
            }
          },
          [
            _c("svg", { attrs: { viewBox: "0 0 56 56" } }, [
              _c("path", {
                attrs: {
                  fill: "currentColor",
                  stroke: "none",
                  d:
                    "M 47.1 47.1 Q 55 39.2 55 28 55 16.85 47.1 8.9 39.2 1 28 1 16.8 1 8.9 8.9 1 16.8 1 28 1 39.2 8.9 47.1 16.8 55 28 55 39.2 55 47.1 47.1 M 28 28 L 28 38.75 28 28 17.25 28 28 28 28 17.25 28 28 38.75 28 28 28 Z"
                }
              }),
              _c("path", {
                attrs: {
                  stroke: "#164194",
                  "stroke-width": "2",
                  "stroke-linejoin": "miter",
                  "stroke-linecap": "butt",
                  "stroke-miterlimit": "4",
                  fill: "none",
                  d: "M 38.75 28 L 28 28 28 38.75 M 28 17.25 L 28 28 17.25 28"
                }
              })
            ])
          ]
        ),
        _c(
          "button",
          {
            staticClass: "btn-zoom",
            on: {
              click: function($event) {
                return _vm.onZoom(-1)
              }
            }
          },
          [
            _c("svg", { attrs: { viewBox: "0 0 56 56" } }, [
              _c("path", {
                attrs: {
                  fill: "currentColor",
                  stroke: "none",
                  d:
                    "M 47.1 47.1 Q 55 39.2 55 28 55 16.85 47.1 8.9 39.2 1 28 1 16.8 1 8.9 8.9 1 16.8 1 28 1 39.2 8.9 47.1 16.8 55 28 55 39.2 55 47.1 47.1 M 38.75 28 L 17.25 28 38.75 28 Z"
                }
              }),
              _c("path", {
                attrs: {
                  stroke: "#164194",
                  "stroke-width": "2",
                  "stroke-linejoin": "miter",
                  "stroke-linecap": "butt",
                  "stroke-miterlimit": "4",
                  fill: "none",
                  d: "M 38.75 28L 17.25 28"
                }
              })
            ])
          ]
        )
      ]),
      _c(
        "MglMap",
        {
          attrs: {
            container: "map-calculator",
            accessToken: _vm.accessToken,
            mapStyle: _vm.mapStyle,
            zoom: _vm.currentZoom,
            "scroll-zoom": false
          },
          on: {
            "update:mapStyle": function($event) {
              _vm.mapStyle = $event
            },
            "update:map-style": function($event) {
              _vm.mapStyle = $event
            },
            load: _vm.onMapLoaded
          }
        },
        _vm._l(_vm.geojson, function(src, key) {
          return _c("MglGeojsonLayer", {
            key: key,
            attrs: {
              "source-id": "geojson-source-" + key.toLowerCase(),
              source: _vm.getGeoJsonSource(key),
              "layer-id": "geojson-line-" + key.toLowerCase(),
              layer: _vm.getGeoJsonLayerLine(key),
              replace: true
            }
          })
        }),
        1
      ),
      _c("div", { staticClass: "legend" }, [
        _c(
          "ul",
          _vm._l(_vm.legends, function(legend) {
            return _c("li", { key: legend.id }, [
              _c("i", {
                staticClass: "color",
                style: { "background-color": legend.color }
              }),
              _c("span", { staticClass: "text" }, [
                _vm._v(_vm._s(legend.label))
              ])
            ])
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }