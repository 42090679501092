import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Block offers
 */

import { Component, Watch } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
import SliderSupplyChange from '@script/components/Slider/SliderSupplyChange';
import gsap from 'gsap';
import { EVENTS } from '@script/config/constant';
let BlockSliderSupply = (_temp = class BlockSliderSupply extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "active", -1);

    _defineProperty(this, "animated", false);

    _defineProperty(this, "visuals", void 0);
  }

  /**
   * Components mounted
   */
  mounted() {
    this.attachEventsHandlers();
  }

  attachEventsHandlers() {
    this.visuals = this.$el.querySelectorAll('.visuals > li'); // Initialize components state

    gsap.set(this.visuals, {
      zIndex: 1,
      xPercent: 100
    });
    this.$events.$on(EVENTS.SWIPER_SLIDE_CHANGE, this.set);
    this.set(0);
  }

  set(index) {
    if (!this.animated) {
      this.active = index;
    }
  }

  initTimeline(incoming, outcoming) {
    const inVisual = this.visuals[incoming];
    const outVisual = this.visuals[outcoming];
    const direction = outcoming < incoming ? -1 : 1;
    const timeline = gsap.timeline({
      paused: true,
      onStart: () => {
        this.animated = true;
      },
      onComplete: () => {
        this.animated = false;
      }
    });
    timeline.addLabel('start');
    /**
     * Queue medias
     */

    if (outVisual instanceof HTMLElement) {
      timeline.fromTo(outVisual, {
        zIndex: 1
      }, {
        xPercent: 100 * direction,
        duration: 1.2,
        ease: 'power4.easeInOut'
      }, 'start');
    }

    if (inVisual instanceof HTMLElement) {
      timeline.fromTo(inVisual, {
        xPercent: -100 * direction,
        zIndex: 2
      }, {
        xPercent: 0,
        duration: 1,
        ease: 'power2.easeInOut'
      }, 'start');
    }

    timeline.play();
  }

}, _temp);

__decorate([Watch('active')], BlockSliderSupply.prototype, "initTimeline", null);

BlockSliderSupply = __decorate([Component({
  components: {
    SliderSupplyChange
  }
})], BlockSliderSupply);
export default BlockSliderSupply;