import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Calculator map
 */

import { Component, Prop, Watch } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
import { MglMap, MglGeojsonLayer } from 'v-mapbox';
import { kml } from '@tmcw/togeojson';
import { MAPBOX } from "@script/config/constant";
let mapRef = null;
var COLORS;

(function (COLORS) {
  COLORS[COLORS["#ff9400"] = 0] = "#ff9400";
  COLORS[COLORS["#ffe100"] = 1] = "#ffe100";
  COLORS[COLORS["#833EEE"] = 2] = "#833EEE";
  COLORS[COLORS["#00b3e4"] = 3] = "#00b3e4";
  COLORS[COLORS["#ff74e3"] = 4] = "#ff74e3";
})(COLORS || (COLORS = {}));

let CalculatorMap = (_temp = class CalculatorMap extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "MAXZOOM", 16);

    _defineProperty(this, "MINZOOM", 1);

    _defineProperty(this, "currentZoom", 1);

    _defineProperty(this, "accessToken", MAPBOX.TOKEN);

    _defineProperty(this, "mapStyle", MAPBOX.STYLE);

    _defineProperty(this, "legends", []);

    _defineProperty(this, "geojson", null);

    _defineProperty(this, "mapboxRef", null);

    _defineProperty(this, "mapActions", null);

    _defineProperty(this, "currentBounds", {
      top: 20,
      right: 20,
      bottom: 20,
      left: 20
    });

    _defineProperty(this, "source", void 0);

    _defineProperty(this, "legend", void 0);
  }

  /**
   * Created
   */
  created() {
    this.initGeojson();
  }
  /**
   * Mounted
   */


  mounted() {
    window.addEventListener('resize', this.onWindowResize);
  }
  /**
   * Init geojson data source
   */


  initGeojson() {
    Object.keys(this.source).forEach(key => {
      const xml = this.source[key];
      const geojson = kml(new DOMParser().parseFromString(xml, 'text/xml'));
      this.geojson = { ...this.geojson,
        [key]: geojson
      };
    });
  }
  /**
   * Get geojson source
   * @returns Object
   */


  getGeoJsonSource(key) {
    return {
      type: 'geojson',
      data: this.geojson[key]
    };
  }
  /**
   * Get geojson layer line
   * @returns Object
   */


  getGeoJsonLayerLine(key) {
    return {
      id: `geojson-line-${key.toLowerCase()}`,
      type: 'line',
      source: `geojson-source-${key.toLowerCase()}`,
      paint: {
        'line-color': ['case', ['==', ['get', 'styleUrl'], '#Road'], COLORS[0], ['==', ['get', 'styleUrl'], '#Rail'], COLORS[1], ['==', ['get', 'styleUrl'], '#Air'], COLORS[2], ['==', ['get', 'styleUrl'], '#Sea'], COLORS[3], COLORS[4]],
        'line-width': ['get', 'stroke-width']
      },
      filter: ['==', '$type', 'LineString']
    };
  }
  /**
   * Map is loaded
   * @param event Event
   */


  onMapLoaded(event) {
    this.onWindowResize();
    this.mapActions = event.component.actions;
    this.mapboxRef = event.component.mapbox;
    mapRef = event.map;
    mapRef.resize();
    this.generateLegend();
  }
  /**
   * Fit map bounds
   * @param coordinates Object
   */


  fitBounds(coordinates) {
    this.mapActions.fitBounds(coordinates, {
      padding: { ...this.currentBounds
      }
    });
  }
  /**
   * Set bounds depending coordinates
   */


  setBoundsLocations() {
    const SOURCES = this.geojson ? Object.values(this.geojson) : [];

    if (SOURCES.length) {
      let coordinates = SOURCES[0].features[0].geometry.coordinates;
      const bounds = new this.mapboxRef.LngLatBounds(coordinates[0], coordinates[0]);
      SOURCES.forEach(src => {
        src.features.forEach(feature => {
          coordinates = feature.geometry.coordinates;

          for (const coord of coordinates) {
            bounds.extend(coord);
          }
        });
      });
      this.fitBounds(bounds);
    }
  }
  /**
   * Zoom
   * @param level Zoom level
   */


  async onZoom(level) {
    if (mapRef) {
      this.currentZoom = mapRef.getZoom();
    }

    let targetZoom = this.currentZoom + level;
    targetZoom = targetZoom < this.MINZOOM ? this.MINZOOM : targetZoom > this.MAXZOOM ? this.MAXZOOM : targetZoom;
    await this.mapActions.zoomTo(targetZoom);
    this.currentZoom = targetZoom;
  }
  /**
   * WIndows resize
   */


  onWindowResize() {
    setTimeout(() => {
      const container = this.$el.querySelector('.mgl-map-wrapper');

      if (container) {
        const bounds = container.getBoundingClientRect();

        if (bounds.width) {
          const basePadding = bounds.width * 0.05;
          this.currentBounds = {
            top: basePadding,
            right: basePadding,
            bottom: basePadding,
            left: basePadding
          };
        }
      }
    }, 250);
  }

  generateLegend() {
    Object.keys(this.geojson).forEach(key => {
      const {
        features
      } = this.geojson[key];
      const initial = features[0];
      const initLabel = this.getFeaturesProperty(initial, 'styleUrl');
      const label = features.reduce((accu, item) => {
        const value = this.getFeaturesProperty(item, 'styleUrl');

        if (accu && !accu.find(({
          id
        }) => id === value)) {
          accu.push(this.setLegend(value));
        }

        return accu;
      }, [{ ...this.setLegend(initLabel)
      }]);
      this.legends.push(label);
    });
    this.legends = [...new Set(this.legends.flat().map(obj => JSON.stringify(obj)))].map(item => JSON.parse(item));
  }

  getFeaturesProperty(feature, prop) {
    const {
      properties
    } = feature;
    return properties[prop];
  }

  setLegend(value) {
    let legend = {};

    switch (value) {
      case '#Road':
        legend = {
          id: '#Road',
          label: this.legend.road,
          color: COLORS[0]
        };
        break;

      case '#Rail':
        legend = {
          id: '#Rail',
          label: this.legend.rail,
          color: COLORS[1]
        };
        break;

      case '#Air':
        legend = {
          id: '#Air',
          label: this.legend.air,
          color: COLORS[2]
        };
        break;

      case '#Sea':
        legend = {
          id: '#Sea',
          label: this.legend.sea,
          color: COLORS[3]
        };
        break;

      default:
        legend = {
          id: '#InlandWaterways',
          label: this.legend.inlandWaterways,
          color: COLORS[4]
        };
        break;
    }

    return legend;
  }

}, _temp);

__decorate([Prop()], CalculatorMap.prototype, "source", void 0);

__decorate([Prop()], CalculatorMap.prototype, "legend", void 0);

__decorate([Watch('currentBounds')], CalculatorMap.prototype, "setBoundsLocations", null);

CalculatorMap = __decorate([Component({
  components: {
    MglMap,
    MglGeojsonLayer
  }
})], CalculatorMap);
export default CalculatorMap;