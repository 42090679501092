import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

/*
 * Copyright © 2021 - яαvoroηα
 *
 * @project Sage
 * @file Main.ts
 * @author ravorona
 */
import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import VueCompositionAPI from '@vue/composition-api';
import MainHeader from '@script/components/MainHeader/MainHeader.vue';
import Dropdown from '@script/components/Dropdown/Dropdown.vue';
import Hero from '@script/components/Hero/Hero.vue';
import AccordionStandard from '@script/components/Accordion/AccordionStandard.vue';
import AccordionMobile from '@script/components/Accordion/AccordionMobile.vue';
import BlockAdvantages from '@script/components/BlockAdvantages/BlockAdvantages.vue';
import SliderStandard from '@script/components/Slider/SliderStandard.vue';
import SliderMobile from '@script/components/Slider/SliderMobile.vue';
import SliderTestimonials from '@script/components/Slider/SliderTestimonials.vue';
import SliderScroll from '@script/components/Slider/SliderScroll.vue';
import SliderSupplyChange from '@script/components/Slider/SliderSupplyChange.vue';
import SliderOffers from '@script/components/Slider/SliderOffers.vue';
import BlockBusinessCases from '@script/components/BlockBusinessCases/BlockBusinessCases.vue';
import BlockSliderSupply from '@script/components/BlockSliderSupply/BlockSliderSupply.vue';
import BlockTextSupply from '@script/components/BlockTextSupply/BlockTextSupply.vue';
import Selectbox from '@script/components/Selectbox/Selectbox.vue';
import VideoPlayer from '@script/components/VideoPlayer/VideoPlayer.vue';
import SearchForm from '@script/components/SearchForm/SearchForm.vue';
import SocialShare from '@script/components/SocialShare/SocialShare.vue';
import HeaderSupplyChange from '@script/components/HeaderSupplyChange/HeaderSupplyChange';
import HeaderCareer from '@script/components/HeaderCareer/HeaderCareer';
import BlockTimeline from '@script/components/BlockTimeline/BlockTimeline';
import ReadMore from '@script/components/ReadMore/ReadMore';
import DefaultComponent from '@script/mixins/DefaultComponent';
import BlockSimplePresentation from '@script/components/Block-simple-presentation';
import BlockFaq from '@script/components/Block-faq';
import BehaviorLinks from '@script/components/BehaviorLinks';
import Single from '@script/components/Single/Single';
import PostFilters from '@script/components/PostFilters/PostFilters';
import Publications from '@script/components/Publications';
import PublicationsSelectbox from '@script/components/Publications/PublicationsSelectbox';
import ContactForm from '@script/components/ContactForm/ContactForm';
import NewContactForm from '@script/components/NewContactForm/NewContactForm';
import Archives from '@script/components/Archives/Archives';
import BlockLocations from '@script/components/BlockLocations/BlockLocations.vue';
import JobOffers from '@script/components/JobOffers';
import JobOfferForm from '@script/components/JobOfferForm';
import GuidedVisit from '@script/components/GuidedVisit';
import BlockWPButtons from '@script/components/BlockWPButtons';
import Glossary from '@script/components/Glossary/Glossary';
import CalculatorForm from '@script/components/Calculator/CalculatorForm';
import CalculatorSwitcher from '@script/components/Calculator/CalculatorSwitcher';
import Countries from '@script/components//Calculator/Countries.vue';
import City from '@script/components//Calculator/City.vue';
import Graphic from '@script/components//Calculator/Graphic.vue';
import CalculatorMap from '@/scripts/components/Calculator/CalculatorMap.vue';
import UIkit from 'uikit';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import SeeMore from '@script/components/SeeMore/SeeMore.vue';
import PopupPardotForm from '@script/components/PopupPardotForm';
import SliderHero from '@script/components/Slider/SliderHero.vue';
import SliderTeam from '@script/components/Slider/SliderTeam.vue';
import SliderCareer from '@script/components/Slider/SliderCareer.vue';
import SliderSocialFeeds from '@script/components/Slider/SliderSocialFeeds.vue';
import Newsletter from '@script/components/Newsletter';
Vue.config.productionTip = false;
Vue.use(VueCompositionAPI);
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
let Main = (_temp = class Main extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "scrollbar", void 0);
  }

  setup() {
    this.lazyloadProcess();
    this.attachEventsHandlers();
    BlockSimplePresentation.init();
    BlockFaq.init();
    BehaviorLinks.init();
    Single.init();
    Publications.init();
    JobOffers.init();
    JobOfferForm.init();
    GuidedVisit.init();
    BlockWPButtons.init();
    PopupPardotForm.ini();
    Newsletter.init();
  }

  attachEventsHandlers() {
    // Main animation
    UIkit.scrollspy('main', {
      target: '.animated',
      cls: 'animated-active',
      hidden: false,
      delay: 80,
      offsetTop: -20
    });
    window.addEventListener('scroll', this.lazyloadProcess);
    window.addEventListener('load', window.initDragDrop);
    window.addEventListener('load', this.appendHeightHeaderBanner);
    window.addEventListener('resize', this.appendHeightHeaderBanner); // console.log((<any>window).initDragDrop)
  }
  /**
   * Image lazyload
   * fallback for modern browser lazyload https://web.dev/lazy-loading-images/
   */


  lazyloadProcess() {
    const medias = document.querySelectorAll('.media-element img:not(.loaded)');
    medias.forEach(media => {
      media.addEventListener('load', () => {
        media.classList.add('loaded');
      });
    });
    /* if ('IntersectionObserver' in window) {
        const imageObserver = new IntersectionObserver(
            entries => {
                entries.forEach(
                    entry => {
                        if (entry.isIntersecting) {
                            const image = entry.target
                             image.classList.add('loaded')
                            imageObserver.unobserve(image)
                        }
                    }
                )
            }
        )
    }
     medias.forEach(
        media => {
            imageObserver.observe(media)
        }
    ) */
  }

  appendHeightHeaderBanner() {
    const bodyPageWrapper = document.querySelector('body:not(.home)');
    const mainHeader = document.querySelector('.main-header');

    if (bodyPageWrapper instanceof HTMLBodyElement && mainHeader instanceof HTMLElement) {
      const mainWrapper = bodyPageWrapper.querySelector('.main-wrapper');
      const stickyMenuActive = mainHeader.querySelector('.nav-primary--list .has-submenu.active .sticky-submenu');
      const mainHeaderHeight = mainHeader.offsetHeight;
      let stickyMenuActiveHeight = 0;

      if (stickyMenuActive instanceof HTMLElement) {
        stickyMenuActiveHeight = stickyMenuActive.offsetHeight;
      }

      mainWrapper.style.marginTop = mainHeaderHeight + stickyMenuActiveHeight + 'px';
    }
  }

}, _temp);
Main = __decorate([Component({
  components: {
    MainHeader,
    Dropdown,
    Selectbox,
    AccordionStandard,
    AccordionMobile,
    SliderStandard,
    SliderMobile,
    SliderTestimonials,
    SliderScroll,
    SliderSupplyChange,
    SliderOffers,
    SliderHero,
    SliderTeam,
    SliderCareer,
    SliderSocialFeeds,
    BlockBusinessCases,
    BlockSliderSupply,
    BlockTextSupply,
    Hero,
    SeeMore,
    BlockAdvantages,
    VideoPlayer,
    SearchForm,
    HeaderSupplyChange,
    HeaderCareer,
    ReadMore,
    BlockTimeline,
    PostFilters,
    PublicationsSelectbox,
    ContactForm,
    NewContactForm,
    Archives,
    BlockLocations,
    SocialShare,
    Glossary,
    CalculatorForm,
    CalculatorSwitcher,
    Countries,
    City,
    Graphic,
    CalculatorMap
  }
})], Main);
export default Main;