import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * See More
 */

import { Component } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
import { EVENTS } from '@script/config/constant';
let SeeMore = (_temp = class SeeMore extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "DEFAULT_LABEL_OPEN", 'See less');

    _defineProperty(this, "DEFAULT_LABEL_CLOSE", 'See more');

    _defineProperty(this, "isOpened", false);

    _defineProperty(this, "buttonEl", null);

    _defineProperty(this, "labelOff", '');

    _defineProperty(this, "labelOn", '');

    _defineProperty(this, "contentHeightFixe", '');

    _defineProperty(this, "contentStyle", {});

    _defineProperty(this, "defaultStyleHeight", '0px');
  }

  mounted() {
    this.$events.$on(EVENTS.ON_TOGGLE_SWITCHER, this.resolveHeightAndRender);
    this.buttonEl = this.$el.querySelector('button');
    this.resolveHeightAndRender();
  }

  resolveHeightAndRender() {
    // Get content real height while it's invisible
    setTimeout(() => {
      var _this$$el$querySelect;

      const bounds = (_this$$el$querySelect = this.$el.querySelector('.see-more-content')) === null || _this$$el$querySelect === void 0 ? void 0 : _this$$el$querySelect.getBoundingClientRect();
      this.isOpened = false;

      if (bounds === null || bounds === void 0 ? void 0 : bounds.height) {
        this.contentHeightFixe = `${(bounds === null || bounds === void 0 ? void 0 : bounds.height) + 10}px` || ''; // Hide content by setting it height to zero as it has overflow hidden

        this.contentStyle = {
          height: this.defaultStyleHeight
        };
      }

      if (this.buttonEl) {
        this.labelOff = this.buttonEl.dataset.labelClose || this.DEFAULT_LABEL_CLOSE;
        this.labelOn = this.buttonEl.dataset.labelOpen || this.DEFAULT_LABEL_OPEN;
        this.buttonEl.addEventListener('click', this.onClickExpand);
        this.changeButtonLabel();
      }
    }, 100);
  }

  changeButtonLabel() {
    if (this.buttonEl) {
      this.buttonEl.innerText = this.isOpened ? this.labelOn : this.labelOff;
    }
  }

  onClickExpand() {
    this.isOpened = !this.isOpened;
    this.contentStyle = this.isOpened ? {
      height: this.contentHeightFixe
    } : {
      height: this.defaultStyleHeight
    };
    this.changeButtonLabel();
  }

}, _temp);
SeeMore = __decorate([Component], SeeMore);
export default SeeMore;