import { __decorate } from "tslib";
/**
 * Block offers
 */

import { Component } from 'vue-property-decorator';
import SwitcherComponent from '@script/mixins/SwitcherComponent';
import SeeMore from '@script/components/SeeMore/SeeMore.vue';
import { EVENTS } from '@script/config/constant';
import UIkit from 'uikit';
let BlockAdvantages = class BlockAdvantages extends SwitcherComponent {
  mounted() {
    this.listenSwitcher();
  }

  listenSwitcher() {
    UIkit.util.on('.tabs-content', 'show', this.onSwitcherShow);
  }
  /**
   * Listen to switch
   * */


  onSwitcherShow() {
    this.$events.$emit(EVENTS.ON_TOGGLE_SWITCHER);
  }

};
BlockAdvantages = __decorate([Component({
  components: {
    SeeMore
  }
})], BlockAdvantages);
export default BlockAdvantages;