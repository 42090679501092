var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "slider slider-social-feeds swiper-container" },
    [_vm._t("default"), _c("div", { staticClass: "swiper-pagination" })],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }