import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Calculator Graphic
 */

import { Component, Prop } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
import { Chart, BarElement, BarController, CategoryScale, LinearScale, Legend } from 'chart.js';
Chart.register(BarElement, BarController, CategoryScale, LinearScale, Legend);
let Graphic = (_temp = class Graphic extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "chart", void 0);

    _defineProperty(this, "images", []);

    _defineProperty(this, "ticks", void 0);

    _defineProperty(this, "step", void 0);

    _defineProperty(this, "picto", void 0);

    _defineProperty(this, "colors", void 0);
  }

  mounted() {
    this.createPicto();
    this.createChart();
  }

  createPicto() {
    Object.values(this.picto).forEach(img => {
      const image = new Image();
      image.src = img;
      this.images.push(image);
    });
  }

  createChart() {
    const DATA = {
      labels: Object.keys(this.ticks),
      datasets: this.createDataset()
    };
    const OPTIONS = {
      responsive: true,
      animation: false,
      layout: {
        padding: {
          bottom: 60
        }
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false
          },
          ticks: {
            display: false
          },
          margins: {
            bottom: 20
          }
        },
        y: {
          grid: {
            display: false
          },
          ticks: {
            beginAtZero: true // stepSize: this.step

          }
        }
      },
      plugins: {
        htmlLegend: {
          containerID: 'graphic-legend'
        },
        legend: {
          display: false
        }
      }
    };
    this.chart = new Chart(this.$el.querySelector('.graphic'), {
      type: 'bar',
      data: DATA,
      options: OPTIONS,
      plugins: [{
        id: 'custom_labels',
        afterDraw: this.drawChartPicto
      }, {
        id: 'htmlLegend',
        afterUpdate: this.htmlLegend
      }]
    });
  }

  drawChartPicto(chart) {
    const CTX = chart.ctx;
    const X_AXIS = chart.scales.x;
    const Y_AXIS = chart.scales.y;
    X_AXIS.ticks.forEach((value, index) => {
      const X = X_AXIS.getPixelForTick(index);
      const IMAGE = this.images[index];

      if (IMAGE !== undefined) {
        if (IMAGE.complete) {
          CTX.drawImage(IMAGE, X - 12, Y_AXIS.bottom + 10);
        } else {
          IMAGE.onload = () => chart.draw();
        }
      }
    });
  }

  htmlLegend(chart, args, options) {
    const container = this.$el.querySelector(`#${options.containerID}`);
    let ul = container === null || container === void 0 ? void 0 : container.querySelector('ul');

    if (!ul) {
      ul = document.createElement('ul');
      container === null || container === void 0 ? void 0 : container.appendChild(ul);
    } // Remove old legend items


    while (ul.firstChild) {
      ul.firstChild.remove();
    } // Reuse the built-in legendItems generator


    const items = chart.options.plugins.legend.labels.generateLabels(chart);
    items.forEach(item => {
      var _ul;

      const li = document.createElement('li');

      li.onclick = () => {
        const {
          type
        } = chart.config;

        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        }

        chart.update();
      }; // Color box


      const boxSpan = document.createElement('span');
      boxSpan.style.background = item.fillStyle === 'rgba(0,0,0,0.1)' ? '#ff9400' : item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle === 'rgba(0,0,0,0.1)' ? '#ff9400' : item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + 'px'; // Text

      const textContainer = document.createElement('p');
      textContainer.style.color = item.fontColor;
      textContainer.style.margin = '0';
      textContainer.style.padding = '0';
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);
      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      (_ul = ul) === null || _ul === void 0 ? void 0 : _ul.appendChild(li);
    });
  }

  createDataset() {
    const dataset = [];
    Object.keys(this.ticks).forEach((key, index) => {
      var _this$colors$key;

      const LENGTH = Object.entries(this.colors).length;
      dataset.push({
        label: key,
        borderRadius: 10,
        data: this.ticks[key],
        maxBarThickness: 45,
        backgroundColor: (_this$colors$key = this.colors[key]) !== null && _this$colors$key !== void 0 ? _this$colors$key : '#ff9400',
        order: this.colors[key] ? LENGTH - index : LENGTH + 1
      });
    });
    return dataset;
  }

}, _temp);

__decorate([Prop()], Graphic.prototype, "ticks", void 0);

__decorate([Prop()], Graphic.prototype, "step", void 0);

__decorate([Prop()], Graphic.prototype, "picto", void 0);

__decorate([Prop()], Graphic.prototype, "colors", void 0);

Graphic = __decorate([Component], Graphic);
export default Graphic;