import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import DefaultComponent from '@script/mixins/DefaultComponent';
import { EVENTS } from '@script/config/constant';
let BlockTextSupply = (_temp = class BlockTextSupply extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isDesktop", void 0);

    _defineProperty(this, "timeline", void 0);
  }

  /**
   * Created
   */
  created() {
    this.setDesktop();
  }
  /**
   * Mounted
   */


  mounted() {
    this.attachEventsHandlers();
  }
  /**
   * Attach events handlers
   */


  attachEventsHandlers() {
    this.$events.$on(EVENTS.WINDOW_RESIZE, this.onWindowResize);
    this.onWindowResize();
  }
  /**
   * Set desktop
   */


  setDesktop() {
    this.isDesktop = window.innerWidth > 1024;
  }
  /**
   * Init the animation on scroll
   */


  scrollAnimationTimeline() {
    const picto = this.$el.querySelector('.picto');
    const timeline = gsap.timeline({
      scrollTrigger: {
        id: 'text-supply-change',
        trigger: this.$el,
        scrub: 1,
        pin: false,
        markers: false,
        start: 'top 50%',
        end: 'bottom 50%'
      }
    });
    timeline.addLabel('start');

    if (picto instanceof HTMLElement) {
      timeline.to(picto, {
        yPercent: 100,
        duration: 3,
        ease: 'power4.inOut'
      }, 'start');
    }
  }

  onWindowResize() {
    const trigger = ScrollTrigger.getById('text-supply-change');

    if (this.timeline) {
      this.timeline.clear().kill();
    }

    if (trigger) {
      trigger.kill(true);
    }

    gsap.set(this.$el.querySelector('.picto'), {
      clearProps: 'all'
    });
    this.setDesktop();

    if (this.isDesktop) {
      this.scrollAnimationTimeline();
    }
  }

}, _temp);
BlockTextSupply = __decorate([Component], BlockTextSupply);
export default BlockTextSupply;