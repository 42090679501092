import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

/**
 * Search all link in the page => linkListInPage
 * Get all form in option page => listOptionPagePardotForm
 * Lopp linkListInPage and search if id is in listOptionPagePardotForm
 * is exist add in new variable
 */
export default class PopupPardotForm {
  static ini() {
    PopupPardotForm.$wrapper = document.querySelector('.popup-pardot-form'); // console.log(console.log('init pardot'))

    if (PopupPardotForm.$wrapper) {
      PopupPardotForm.$formWrapper = document.querySelector('.popup-pardot-form .pardot-apply-form-inner');
      PopupPardotForm.linkListInPage = document.querySelectorAll('a[href*="#form-pardot-"]');
      PopupPardotForm.listOptionPagePardotForm = JSON.parse(PopupPardotForm.$wrapper.getAttribute('data-popup'));
      PopupPardotForm.$close = PopupPardotForm.$wrapper.querySelector('.close');
      PopupPardotForm.loppLinkList(); // pass after loppLinkList

      PopupPardotForm.getHash(); // console.log('listOptionPagePardotForm', PopupPardotForm.listOptionPagePardotForm)
    }
  }
  /**
   * Lopp linkListInPage
   */


  static loppLinkList() {
    // console.log('method loppLinkList is loaded')
    PopupPardotForm.linkListInPage.forEach(function (val, key, el) {
      const linkHref = val.getAttribute('href');
      PopupPardotForm.searchTag(linkHref, key, val); // console.log(el.length, key, Object.is(el.length - 1, key))
      // Last element

      if (Object.is(el.length - 1, key)) {
        // console.log('is last')
        PopupPardotForm.addLinkEvent();
      }
    });
  }
  /**
   * Search the id
   * @param $href
   */


  static searchTag($href, index = -1, val = null) {
    const formDetail = PopupPardotForm.listOptionPagePardotForm.filter(obj => {
      // console.log('test obj.id', obj.id)
      return obj.id === $href;
    });

    if (formDetail.length !== 0) {
      if (!PopupPardotForm.isHash) {
        PopupPardotForm.linkInPage.push(formDetail);

        if (val !== null && index !== -1) {
          // console.log('formDetail', formDetail)
          val.classList.add('form-pardot-anchor');
          val.setAttribute('data-anchor', formDetail[0].slug);
        }
      } else {
        PopupPardotForm.appentIframeTag(formDetail[0].slug); // console.log('test', formDetail)
      }
    }
  }
  /**
   * Add event listener
   */


  static addLinkEvent() {
    if (document.querySelector('.form-pardot-anchor') !== null) {
      document.querySelectorAll('.form-pardot-anchor').forEach(value => {
        value === null || value === void 0 ? void 0 : value.addEventListener('click', el => {
          const slug = value.getAttribute('data-anchor');
          PopupPardotForm.appentIframeTag(slug);
          el.preventDefault;
        });
      });
    }

    PopupPardotForm.$close.addEventListener('click', () => {
      PopupPardotForm.close();
    });
  }

  static open() {
    var _document$querySelect;

    (_document$querySelect = document.querySelector('body')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.classList.add('open-popup-pardot-form');
  }

  static close() {
    var _document$querySelect2, _document$querySelect3;

    (_document$querySelect2 = document.querySelector('body')) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.classList.remove('open-popup-pardot-form');
    (_document$querySelect3 = document.querySelector('.pardot-apply-form-inner iframe')) === null || _document$querySelect3 === void 0 ? void 0 : _document$querySelect3.remove();
  }
  /**
   * Append iframe in DOM
   */


  static appentIframeTag(slug) {
    const iframe = document.createElement('iframe');
    iframe.width = '100%';
    iframe.height = '95%';

    if (slug !== null) {
      iframe.src = slug;
      iframe.classList.add('pardotform');

      if (PopupPardotForm.$formWrapper) {
        var _PopupPardotForm$$for;

        PopupPardotForm.$formWrapper.innerHTML = '';
        (_PopupPardotForm$$for = PopupPardotForm.$formWrapper) === null || _PopupPardotForm$$for === void 0 ? void 0 : _PopupPardotForm$$for.append(iframe);
        PopupPardotForm.open();
      }
    }
  }

  static getHash() {
    const hash = window.location.hash;

    if (hash) {
      PopupPardotForm.isHash = true;
      PopupPardotForm.searchTag(hash);
    }
  }

}

_defineProperty(PopupPardotForm, "$wrapper", void 0);

_defineProperty(PopupPardotForm, "$formWrapper", void 0);

_defineProperty(PopupPardotForm, "linkListInPage", void 0);

_defineProperty(PopupPardotForm, "listOptionPagePardotForm", void 0);

_defineProperty(PopupPardotForm, "linkInPage", []);

_defineProperty(PopupPardotForm, "$close", void 0);

_defineProperty(PopupPardotForm, "isHash", false);