import "core-js/modules/web.dom-collections.iterator";
import "core-js/modules/web.url";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";
export default class Publications {
  static init() {
    const $publicationFilters = document.querySelector('.publication-filters');

    if ($publicationFilters) {
      const $filters = $publicationFilters.querySelectorAll('.filters li a');
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const $search = $publicationFilters.querySelector('input');
      Publications.selectedCategory = urlParams.get('pubcat');
      Publications.search = urlParams.get('search');
      Publications.urlPage = window.location.href.split('?')[0];

      if (Publications.search) {
        $publicationFilters.querySelector('input').value = Publications.search;
      }

      $filters.forEach($filterCta => {
        $filterCta.addEventListener('click', event => {
          event.preventDefault();
          Publications.selectedCategory = $filterCta.getAttribute('data-filter');
          Publications.goFilter();
        });
      });
      $search === null || $search === void 0 ? void 0 : $search.addEventListener('focusin', () => {
        $search.addEventListener('keyup', event => {
          if (event.key === 'Enter') {
            Publications.selectedCategory = 'all';
            Publications.goFilter();
          }
        });
      });
      $search === null || $search === void 0 ? void 0 : $search.addEventListener('focusout', () => {
        $search.removeEventListener('keyup', event => {
          if (event.key === 'Enter') {
            Publications.selectedCategory = 'all';
            Publications.goFilter();
          }
        });
      });
    }
  }

  static goFilter() {
    const query = [];
    const $publicationFilters = document.querySelector('.publication-filters');
    let urlNoQuery = true;
    Publications.search = $publicationFilters === null || $publicationFilters === void 0 ? void 0 : $publicationFilters.querySelector('input').value;

    if (Publications.selectedCategory !== '' && Publications.selectedCategory !== 'all') {
      query.push(`pubcat=${Publications.selectedCategory}`);
      urlNoQuery = false;
    }

    if (Publications.search !== '') {
      query.push(`search=${Publications.search}`);
      urlNoQuery = false;
    }

    if (query.length) {
      var newURL = `${Publications.urlPage}`;

      if (!urlNoQuery) {
        var spanElement = document.querySelector('[data-url-permalink]');
        var permalinkValue = spanElement ? spanElement.getAttribute('data-url-permalink') : '';

        if (permalinkValue) {
          newURL = permalinkValue;
        }
      }

      window.location.href = `${newURL}?${query.join('&')}`;
    } else {
      window.location.href = Publications.urlPage;
    }
  }

}

_defineProperty(Publications, "selectedCategory", '');

_defineProperty(Publications, "search", '');

_defineProperty(Publications, "urlPage", '');