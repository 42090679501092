import { __decorate } from "tslib";
/**
 * Slider standard
 */

import { Component } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
let SocialShare = class SocialShare extends DefaultComponent {
  /**
   * Mounted
   */
  mounted() {
    this.attachEventHandlers();
  }
  /**
   * Attach event handlers
   */


  attachEventHandlers() {
    const LINKS = this.$el.querySelectorAll('.links span');

    if (LINKS.length > 0) {
      LINKS.forEach(link => {
        link.addEventListener('click', event => {
          event.preventDefault();
          const trigger = link;
          const data = {
            url: trigger.getAttribute('data-link'),
            name: `${trigger.title.toLowerCase()}-share-window`
          };
          this.socialShareWindow(data);
        });
      });
    }
  }

  socialShareWindow(data) {
    const {
      url,
      name
    } = data;
    const PARAMS = `menubar=no,toolbar=no,status=no,width=480,height=480,left=100,top=100,`;

    if (url) {
      window.open(url, name, PARAMS);
    }
  }

};
SocialShare = __decorate([Component], SocialShare);
export default SocialShare;