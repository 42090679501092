import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Contact form
 */

import { Component, Watch } from 'vue-property-decorator';
import gsap from 'gsap';
import DefaultComponent from '@script/mixins/DefaultComponent'; // import { getMaxHeight } from '@script/utils/utils'

let ContactForm = (_temp = class ContactForm extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "active", -1);

    _defineProperty(this, "animated", false);

    _defineProperty(this, "formToggler", void 0);

    _defineProperty(this, "forms", void 0);

    _defineProperty(this, "title", void 0);
  }

  /**
   * Component mounted
   */
  mounted() {
    this.attachEventsHandlers();
    this.initContactForms();
  }
  /**
   * Attach event handler
   */


  attachEventsHandlers() {
    this.formToggler = this.$el.querySelectorAll('.list-form-toggler li');
    this.forms = this.$el.querySelectorAll('.form');
    this.title = this.$el.querySelector('.subtitle');
    /**
     * Contact form sumitted
     */

    document.addEventListener('wpcf7submit', () => {
      const formList = document.querySelectorAll('.wpcf7-form');
      formList.forEach(element => {
        element.classList.remove('doing-submission');
      });
    }, false);
    /**
     * Contact form sumitted
     */

    document.addEventListener('wpcf7mailsent', this.confirmationTimeline, false);
    this.getHash();
  }
  /**
   * Set active form
   * @param index
   */


  set(index) {
    if (!this.animated) {
      this.active = index;
    }
  }

  showFormTimeline(incoming) {
    const listForm = this.$el.querySelector('.list-form');
    const formToggler = this.$el.querySelector('.list-form-toggler');
    const activeForm = this.forms[incoming];
    const inputfile = this.$el.querySelector('.wpcf7-drag-n-drop-file');
    const inputFileHandler = this.$el.querySelector('.codedropz-upload-handler');
    const timeline = gsap.timeline({
      paused: true,
      onStart: () => {
        this.animated = true;
      },
      onComplete: () => {
        this.animated = false;
      }
    }); // console.log('dnd_cf7_uploader:', window.dnd_cf7_uploader)

    timeline.addLabel('start');

    if (this.formToggler.length) {
      timeline.to(this.formToggler, {
        duration: 1,
        x: 200,
        autoAlpha: 0,
        ease: 'power2.out',
        stagger: 0.2,
        onComplete: () => {
          if (this.title instanceof HTMLElement) {
            this.title.innerText = `${this.title.dataset.text}`;
          }

          formToggler === null || formToggler === void 0 ? void 0 : formToggler.classList.add('hidden');
        }
      }, 'start');
    }

    if (activeForm instanceof HTMLElement && listForm instanceof HTMLElement) {
      timeline.to(activeForm, {
        y: 0,
        autoAlpha: 1,
        duration: 1,
        ease: 'power2.easeInOut',
        onStart: () => {
          listForm.style.height = `${activeForm.clientHeight}px`;
        }
      }, 1.5); // update height

      setInterval(() => {
        listForm.style.height = `${activeForm.clientHeight}px`;
      }, 1000);
    }
    /*if (inputFileHandler instanceof HTMLInputElement) {
        (<HTMLInputElement>inputFileHandler).addEventListener('drop', () => {
            (<HTMLElement>listForm).style.height = `${ activeForm.clientHeight }px`
        });
         (<HTMLInputElement>inputfile).addEventListener('change', () => {
            (<HTMLElement>listForm).style.height = `${ activeForm.clientHeight }px`
        })
    }
     document.addEventListener('click', event => {
        if ((<HTMLElement>event.target).classList.contains('dnd-icon-remove')) {
            const intervalID = setInterval(function() {
                if (!document.body.contains(<HTMLElement>event.target)) {
                    (<HTMLElement>listForm).style.height = `${ activeForm.clientHeight }px`
                    clearInterval(intervalID)
                }
            }, 500)
        }
    })*/


    timeline.play();
  }

  confirmationTimeline() {
    const listForm = this.$el.querySelector('.list-form');
    const activeForm = this.forms[this.active];
    const confirmationMsg = this.$el.querySelector('.confirmation-message');
    const timeline = gsap.timeline({
      paused: true
    });
    timeline.addLabel('start');

    if (activeForm instanceof HTMLElement && listForm instanceof HTMLElement) {
      timeline.to([activeForm, this.title], {
        y: 100,
        autoAlpha: 0,
        duration: 1,
        ease: 'power2.out',
        stagger: 0.2,
        onComplete: () => {
          var _this$title;

          (_this$title = this.title) === null || _this$title === void 0 ? void 0 : _this$title.classList.add('hidden');
          listForm.style.height = '';
        }
      }, 'start');
    }

    if (confirmationMsg instanceof HTMLElement) {
      timeline.to(confirmationMsg, {
        y: 0,
        autoAlpha: 1,
        duration: 1,
        ease: 'power2.easeInOut',
        onStart: () => {
          confirmationMsg.classList.remove('hidden');
        }
      });
    }

    timeline.play();
  }

  getHash() {
    const form = ['#business', '#human-resources', '#media', '#other'];
    const hash = window.location.hash;

    if (hash) {
      const index = form.indexOf(hash);
      this.set(index);
    }
  }
  /**
   * Initialize contact forms
   * @method initContactForms
   * @return {Void}
   */


  initContactForms() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const {
      wpcf7
    } = window;

    if (wpcf7) {
      const formList = document.querySelectorAll('.wpcf7-form');

      if (formList.length) {
        formList.forEach(element => {
          const button = element.querySelector('.cta'); // const files = element.querySelectorAll('.file-placeholder')

          /**
           * Initialize contact forms
           */

          wpcf7.init(element);
          /**
           * Submit button hook
           */

          button === null || button === void 0 ? void 0 : button.addEventListener('click', () => {
            element.classList.add('doing-submission');
          });
        });
      }
    }
  }

}, _temp);

__decorate([Watch('active')], ContactForm.prototype, "showFormTimeline", null);

ContactForm = __decorate([Component], ContactForm);
export default ContactForm;