import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
let SearchForm = class SearchForm extends DefaultComponent {
  mounted() {
    this.attachEventHandler();
  }

  attachEventHandler() {
    const textForm = this.$el.querySelector('.field-search');
    const clearForm = this.$el.querySelector('.field-clear');

    if (clearForm instanceof HTMLElement) {
      clearForm.addEventListener('click', () => {
        textForm.value = '';
      });
    }

    if (textForm instanceof HTMLInputElement) {
      textForm.addEventListener('focusin', () => {
        this.$el.classList.add('is-focused');
      });
      textForm.addEventListener('focusout', () => {
        this.$el.classList.remove('is-focused');
      });
    }
  }

};
SearchForm = __decorate([Component], SearchForm);
export default SearchForm;