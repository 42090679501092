import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.iterator";
import "core-js/modules/web.url";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";
export default class JobOffers {
  static init() {
    const $jobOffersFilters = document.querySelector('.header-job-offers .filter');
    JobOffers.$searchInput = $jobOffersFilters === null || $jobOffersFilters === void 0 ? void 0 : $jobOffersFilters.querySelector('input.search');
    JobOffers.$contractSelect = $jobOffersFilters === null || $jobOffersFilters === void 0 ? void 0 : $jobOffersFilters.querySelector('#contract-types');
    JobOffers.$submit = $jobOffersFilters === null || $jobOffersFilters === void 0 ? void 0 : $jobOffersFilters.querySelector('button.sender');
    JobOffers.$reset = document.querySelector('button.reset');
    JobOffers.$postulerBtn = document.querySelector('.button-apply--wrapper a');
    JobOffers.$postulerBtnHeader = document.querySelector('.header-job-offers--wrapper a.cta');
    JobOffers.$postulerClose = document.querySelector('.job-apply-form .close');

    if ($jobOffersFilters) {
      JobOffers.$filtersElement = $jobOffersFilters;
      JobOffers.initLocations($jobOffersFilters);
      JobOffers.setStartingValues();
      JobOffers.initSearch();
      JobOffers.$submit.addEventListener('click', () => {
        JobOffers.goFilter();
      });
      JobOffers.$reset.addEventListener('click', () => {
        window.location.href = '//' + location.host + location.pathname;
      });
    }

    if (JobOffers.$postulerBtn) {
      JobOffers.$postulerBtn.addEventListener('click', event => {
        event.preventDefault();
        JobOffers.openForm();
      });
    }

    if (JobOffers.$postulerBtnHeader) {
      JobOffers.$postulerBtnHeader.addEventListener('click', event => {
        event.preventDefault();
        JobOffers.openForm();
      });
    }

    if (JobOffers.$postulerClose) {
      JobOffers.$postulerClose.addEventListener('click', () => {
        JobOffers.closeForm();
      });
    }

    if (window.location.href.indexOf('open-popup-form') > -1) {
      JobOffers.openForm();
    }
  }

  static openForm() {
    var _document$querySelect;

    (_document$querySelect = document.querySelector('body')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.classList.add('open-popup-form');
  }

  static closeForm() {
    var _document$querySelect2;

    (_document$querySelect2 = document.querySelector('body')) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.classList.remove('open-popup-form');
  }

  static initSearch() {
    JobOffers.$searchInput.addEventListener('keyup', event => {
      if (event.key === 'Enter') {
        JobOffers.goFilter();
      }
    });
  }

  static initLocations($jobOffersFilters) {
    const $locationInput = $jobOffersFilters.querySelector('input.location');
    const $dropDown = $jobOffersFilters.querySelector('.locations-drop-down');
    const options = $dropDown === null || $dropDown === void 0 ? void 0 : $dropDown.querySelectorAll('.location-name');
    [].forEach.call(options, function ($option) {
      const $currentOption = $option;
      JobOffers.locationList[$currentOption.getAttribute('data-slug')] = [$currentOption.innerHTML, $currentOption.getAttribute('data-parent')];
      JobOffers.locationListSlug[$currentOption.innerHTML] = $currentOption.getAttribute('data-slug');
    }); // console.log(JobOffers.locationList)

    JobOffers.$locationsDropDown = $dropDown;
    JobOffers.$locationInput = JobOffers.$filtersElement.querySelector('input.location');
    $locationInput === null || $locationInput === void 0 ? void 0 : $locationInput.addEventListener('keyup', event => {
      const start = event.target.value;
      const key = event.key;
      let filteredLocations;
      event.preventDefault();

      if (!JobOffers.$filtersElement.classList.contains('focus-location') && key === 'Enter') {
        JobOffers.goFilter();
        return;
      }

      if (start.length > 2) {
        var _JobOffers$$locations;

        filteredLocations = JobOffers.filterLocations($jobOffersFilters, start);
        /* JobOffers.$locationsDropDown.querySelectorAll('.locations-drop-down .location-name').forEach(($location: Element) => {
            (<Element>$location).classList.remove('selected')
        }) */

        JobOffers.removeAllSelectedOnLocation();

        if (key === 'ArrowDown' || key === 'ArrowUp') {
          const filteredLocationsLength = Object.keys(filteredLocations).length;

          if (key === 'ArrowUp') {
            JobOffers.selectedLocationDropDownKey--;
          } else {
            JobOffers.selectedLocationDropDownKey++;
          }

          JobOffers.selectedLocationDropDownKey = Math.min(JobOffers.selectedLocationDropDownKey, filteredLocationsLength);
          JobOffers.selectedLocationDropDownKey = Math.max(JobOffers.selectedLocationDropDownKey, 1);
        } else {
          if (key === 'Enter') {
            JobOffers.selectLocation();
          } else {
            JobOffers.selectedLocationDropDownKey = 1;
          }
        }

        (_JobOffers$$locations = JobOffers.$locationsDropDown.querySelector('.locations-drop-down .location-name:nth-child(' + JobOffers.selectedLocationDropDownKey + ')')) === null || _JobOffers$$locations === void 0 ? void 0 : _JobOffers$$locations.classList.add('selected');
      } else {
        $jobOffersFilters.classList.remove('focus-location');
        /* console.log('Go filter') */
      }
    });
    /* JobOffers.$filtersElement.addEventListener('focusout', () => {
        $jobOffersFilters.classList.remove('focus-location')
    }) */

    JobOffers.$locationsDropDown.addEventListener('click', function (event) {
      // console.log(event.target)
      const $target = event.target;

      if ($target.classList.contains('location-name')) {
        JobOffers.removeAllSelectedOnLocation();
        $target.classList.add('selected');
        JobOffers.selectLocation();
      }
    });
    document.addEventListener('click', function (event) {
      if (!JobOffers.hasParentWithMatchingSelector(event.target, '.locations-drop-down')) {
        $jobOffersFilters.classList.remove('focus-location');
      }
    });
  }

  static removeAllSelectedOnLocation() {
    JobOffers.$locationsDropDown.querySelectorAll('.locations-drop-down .location-name').forEach($location => {
      $location.classList.remove('selected');
    });
  }

  static selectLocation() {
    const currentLocationNameElement = JobOffers.$locationsDropDown.querySelector('.locations-drop-down .location-name:nth-child(' + JobOffers.selectedLocationDropDownKey + ')');

    if (currentLocationNameElement) {
      currentLocationNameElement === null || currentLocationNameElement === void 0 ? void 0 : currentLocationNameElement.classList.add('selected');
      const selectedLocation = JobOffers.$filtersElement.querySelector('.locations-drop-down .location-name.selected');
      const locationName = selectedLocation === null || selectedLocation === void 0 ? void 0 : selectedLocation.innerHTML;
      JobOffers.$locationInput.value = decodeURI(locationName);
      currentLocationNameElement === null || currentLocationNameElement === void 0 ? void 0 : currentLocationNameElement.classList.remove('selected');
      JobOffers.$filtersElement.classList.remove('focus-location');
    }
  }

  static filterLocations($jobOffersFilters, start) {
    const filteredLocation = {};
    const startingLowerCase = start.toLowerCase();
    JobOffers.$locationsDropDown.innerHTML = '';

    for (const locationListKey in JobOffers.locationList) {
      const tempstr = JobOffers.locationList[locationListKey][0];

      if (tempstr.toLowerCase().indexOf(startingLowerCase) !== -1) {
        filteredLocation[locationListKey] = tempstr;
        const tempElement = document.createElement('div');
        tempElement.setAttribute('class', 'location-name');
        tempElement.setAttribute('data-slug', locationListKey);
        tempElement.setAttribute('data-parent', JobOffers.locationList[locationListKey][1]);
        tempElement.innerHTML = tempstr;
        JobOffers.$locationsDropDown.appendChild(tempElement);
      }
    } // console.log(filteredLocation)


    if (Object.keys(filteredLocation).length) {
      $jobOffersFilters.classList.add('focus-location');
    } else {
      $jobOffersFilters.classList.remove('focus-location');
    }

    return filteredLocation;
  }

  static setStartingValues() {
    const QueryString = window.location.search;
    let hasFilters = false;
    JobOffers.urlParams = new URLSearchParams(QueryString);

    if (JobOffers.urlParams.has('search')) {
      JobOffers.$searchInput.value = JobOffers.urlParams.get('search');
      hasFilters = true;
    }

    if (JobOffers.urlParams.has('location')) {
      const urlLocation = JobOffers.urlParams.get('location');
      let tempLocation = urlLocation;
      hasFilters = true;

      if (JobOffers.locationList[urlLocation]) {
        tempLocation = JobOffers.locationList[urlLocation][0];
      } // console.log('tempLocation', tempLocation, JobOffers.locationList)


      if (tempLocation) {
        JobOffers.$locationInput.value = tempLocation;
      }
    }

    if (JobOffers.urlParams.has('contract')) {
      hasFilters = true;
      JobOffers.$contractSelect.value = JobOffers.urlParams.get('contract');
    }

    if (hasFilters && window.innerWidth < 768) {
      var _document$querySelect3;

      (_document$querySelect3 = document.querySelector('.list-job-offers')) === null || _document$querySelect3 === void 0 ? void 0 : _document$querySelect3.scrollIntoView();
    }

    JobOffers.$filtersElement.classList.add('active');
  }

  static goFilter() {
    let params = '';

    if (JobOffers.$searchInput.value.length) {
      JobOffers.urlParams.set('search', JobOffers.$searchInput.value);
    } else {
      JobOffers.urlParams.delete('search');
    }

    if (JobOffers.$locationInput.value.length) {
      const tempLocation = JobOffers.locationListSlug[JobOffers.$locationInput.value];

      if (typeof tempLocation !== 'undefined') {
        JobOffers.urlParams.set('location', tempLocation);
      } else {
        JobOffers.urlParams.set('location', JobOffers.$locationInput.value);
      }
    } else {
      JobOffers.urlParams.delete('location');
    }

    if (JobOffers.$contractSelect.value && !isNaN(parseInt(JobOffers.$contractSelect.value))) {
      JobOffers.urlParams.set('contract', JobOffers.$contractSelect.value);
    } else {
      JobOffers.urlParams.delete('contract');
    }

    params = JobOffers.urlParams.toString();

    if (params !== '') {
      // window.location.search = params
      const urlNoParams = '//' + location.host + location.pathname;
      const actualLocation = urlNoParams.replace(/\/page\/[^d*]/, '');
      const nextUrl = actualLocation + '?' + params;
      window.location.href = nextUrl;
    }
  }

  static hasParentWithMatchingSelector(target, selector) {
    return [...document.querySelectorAll(selector)].some(el => el !== target && el.contains(target));
  }

}

_defineProperty(JobOffers, "locationList", {});

_defineProperty(JobOffers, "locationListSlug", {});

_defineProperty(JobOffers, "selectedLocation", '');

_defineProperty(JobOffers, "$locationInput", void 0);

_defineProperty(JobOffers, "$locationsDropDown", void 0);

_defineProperty(JobOffers, "$filtersElement", void 0);

_defineProperty(JobOffers, "$searchInput", void 0);

_defineProperty(JobOffers, "$contractSelect", void 0);

_defineProperty(JobOffers, "$submit", void 0);

_defineProperty(JobOffers, "$reset", void 0);

_defineProperty(JobOffers, "$postulerBtn", void 0);

_defineProperty(JobOffers, "$postulerBtnHeader", void 0);

_defineProperty(JobOffers, "$postulerClose", void 0);

_defineProperty(JobOffers, "CHARS_LIST", 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890 ');

_defineProperty(JobOffers, "SUPPR_LIST", ['Backspace', 'Delete']);

_defineProperty(JobOffers, "selectedLocationDropDownKey", 0);

_defineProperty(JobOffers, "urlParams", void 0);