import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Video
 */

import { Component, Prop } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
let VideoPlayer = (_temp = class VideoPlayer extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "player", void 0);

    _defineProperty(this, "isHtml", void 0);

    _defineProperty(this, "isAutoplay", false);

    _defineProperty(this, "type", void 0);

    _defineProperty(this, "source", void 0);

    _defineProperty(this, "autoplay", void 0);
  }

  created() {
    this.setType();
  }

  mounted() {
    this.setAutoplay();
    this.initPlayer();
    this.attachEventsHandlers();
  }

  setType() {
    this.isHtml = this.type === 'video';
  }

  setAutoplay() {
    this.isAutoplay = this.autoplay;
  }

  attachEventsHandlers() {
    const poster = this.$el.querySelector('.poster');
    const playButton = this.$el.querySelector('.cta');
    this.player.on('playing', () => {
      this.$el.classList.add('is-playing');
      poster === null || poster === void 0 ? void 0 : poster.classList.add('invisible');
      playButton === null || playButton === void 0 ? void 0 : playButton.classList.add('invisible');
    });

    if (poster instanceof HTMLElement) {
      poster.addEventListener('click', () => {
        this.player.play();
      });
    }

    if (playButton instanceof HTMLElement) {
      playButton.addEventListener('click', event => {
        event.preventDefault();
        this.player.play();
      });
    }
  }

  initPlayer() {
    const video = this.$el.querySelector('#player');
    const options = {
      hideControls: true,
      autoplay: this.isAutoplay,
      muted: this.isAutoplay,
      loop: {
        active: this.isAutoplay
      },
      controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']
    }; // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    this.player = new Plyr(video, options);
  }

}, _temp);

__decorate([Prop()], VideoPlayer.prototype, "type", void 0);

__decorate([Prop()], VideoPlayer.prototype, "source", void 0);

__decorate([Prop({
  default: false
})], VideoPlayer.prototype, "autoplay", void 0);

VideoPlayer = __decorate([Component], VideoPlayer);
export default VideoPlayer;