import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
import Selectbox from '@script/components/Selectbox/Selectbox.vue';
import { EVENTS } from '@script/config/constant';
import Publications from '@script/components/Publications';
let PublicationsSelectbox = (_temp = class PublicationsSelectbox extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "termSlugs", void 0);
  }

  mounted() {
    this.$events.$on(EVENTS.SELECT_UPDATE_OPTION, this.selectionListener);
  }

  selectionListener(option) {
    // console.log('option:', option, this.termSlugs[option], Publications.selectedCategory)
    Publications.selectedCategory = this.termSlugs[option];
    Publications.goFilter();
  }

}, _temp);

__decorate([Prop()], PublicationsSelectbox.prototype, "termSlugs", void 0);

PublicationsSelectbox = __decorate([Component({
  components: {
    Selectbox
  }
})], PublicationsSelectbox);
export default PublicationsSelectbox;