import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Slider
 */

import { Component } from 'vue-property-decorator';
import Swiper, { Navigation, Pagination, Scrollbar, EffectFade, Autoplay } from 'swiper';
import { EVENTS, SWIPERCONFIG } from '@script/config/constant';
import DefaultComponent from '@script/mixins/DefaultComponent';
Swiper.use([Navigation, Pagination, Scrollbar, EffectFade, Autoplay]);
let SliderComponent = (_temp = class SliderComponent extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "sliderComponent", void 0);

    _defineProperty(this, "options", void 0);
  }

  /**
   * Mounted
   */
  mounted() {
    this.initSlider();
    this.attachEventsHandlers();
  }

  attachEventsHandlers() {
    this.sliderComponent.on('activeIndexChange', event => {
      const {
        activeIndex
      } = event;
      this.$events.$emit(EVENTS.SWIPER_SLIDE_CHANGE, activeIndex);
    });
    this.$events.$on(EVENTS.SWIPER_MANUAL_SLIDE_CHANGE, this.slideTo);
  }

  initSlider() {
    const navNext = this.$el.querySelector('.nav-next');
    const navPrev = this.$el.querySelector('.nav-prev');
    const pagination = this.$el.querySelector('.swiper-pagination');
    const scrollbar = this.$el.querySelector('.swiper-scrollbar');
    let configuration = { ...SWIPERCONFIG
    };

    if (this.options) {
      configuration = { ...configuration,
        ...this.options
      };
    } // Navigation


    if (navPrev instanceof HTMLElement && navNext instanceof HTMLElement) {
      configuration.navigation = {
        prevEl: navPrev,
        nextEl: navNext
      };
    } // Pagination


    if (pagination instanceof HTMLElement) {
      configuration.pagination = {
        el: pagination,
        clickable: true
      };
    } // Scrollbar


    if (scrollbar instanceof HTMLElement) {
      configuration.scrollbar = {
        el: scrollbar,
        draggable: true
      };
    }

    this.sliderComponent = new Swiper(this.$el, configuration);
  }

  slideTo(index) {
    this.sliderComponent.slideTo(index);
  }

  stopAutoplay() {
    this.sliderComponent.autoplay.stop();
  }

}, _temp);
SliderComponent = __decorate([Component], SliderComponent);
export default SliderComponent;