var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-player" },
    [
      _vm.isHtml
        ? _c(
            "video",
            {
              attrs: {
                id: "player",
                controls: "",
                crossorigin: "",
                playsinline: ""
              }
            },
            [_c("source", { attrs: { src: _vm.source, type: "video/mp4" } })]
          )
        : _c(
            "div",
            { staticClass: "plyr__video-embed", attrs: { id: "player" } },
            [
              _c("iframe", {
                attrs: {
                  src: _vm.source,
                  allowfullscreen: "",
                  allowtransparency: "",
                  allow: "autoplay"
                }
              })
            ]
          ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }