var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "city-autocomplete" }, [
    _c("label", { attrs: { for: _vm.defaultName } }, [
      _vm._v(_vm._s(_vm.label))
    ]),
    _c("div", { class: ["search-form", { loading: _vm.loading }] }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.search,
            expression: "search"
          }
        ],
        attrs: {
          name: _vm.defaultName,
          id: _vm.defaultName,
          type: "text",
          disabled: _vm.isDisable,
          required: _vm.isRequired
        },
        domProps: { value: _vm.search },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.search = $event.target.value
            },
            _vm.load
          ]
        }
      })
    ]),
    _c(
      "div",
      { class: ["search-results", { show: _vm.result || _vm.message }] },
      [
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.result,
                expression: "result"
              }
            ],
            staticClass: "result"
          },
          _vm._l(_vm.result, function(res) {
            return _c("li", [
              _c(
                "a",
                {
                  attrs: { href: res },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.setValue(res)
                    }
                  }
                },
                [_vm._v(_vm._s(res))]
              )
            ])
          }),
          0
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.message,
                expression: "message"
              }
            ],
            staticClass: "message"
          },
          [_c("p", [_vm._v(_vm._s(_vm.message))])]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }