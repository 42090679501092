var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-container" },
    [
      _vm.currentPage === 0
        ? _c("LocationsListCountries", { attrs: { countries: _vm.countries } })
        : _vm._e(),
      _vm.currentPage === 1
        ? _c("LocationsList", { attrs: { locations: _vm.currentLocations } })
        : _vm._e(),
      _vm.currentPage === 2
        ? _c("LocationView", { attrs: { location: _vm.selectedLocation } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }