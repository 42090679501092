import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import gsap from 'gsap';
import DefaultComponent from '@script/mixins/DefaultComponent';
import SliderStandard from '@script/components/Slider/SliderStandard.vue';
import { EVENTS } from '@script/config/constant';
let BlockBusinessCases = (_temp = class BlockBusinessCases extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "active", -1);

    _defineProperty(this, "animated", false);

    _defineProperty(this, "sliderContent", void 0);

    _defineProperty(this, "pagination", void 0);
  }

  /**
   * Components mounted
   */
  mounted() {
    this.attachEventsHandlers();
  }

  attachEventsHandlers() {
    this.sliderContent = this.$el.querySelectorAll('.case-text-list li');
    this.pagination = this.$el.querySelectorAll('.case-custom-pagination span');
    this.pagination.forEach((element, index) => {
      element.addEventListener('click', () => {
        // this.$children[0].slideTo(index)
        this.$events.$emit(EVENTS.SWIPER_MANUAL_SLIDE_CHANGE, index);
      });
    });
    this.$events.$on(EVENTS.SWIPER_SLIDE_CHANGE, this.set);
    this.set(0);
  }

  set(index) {
    if (!this.animated) {
      this.active = index;
    }
  }

  initTimeline(incoming, outcoming) {
    const outText = this.sliderContent[outcoming];
    const inText = this.sliderContent[incoming];
    const timeline = gsap.timeline({
      paused: true,
      onStart: () => {
        var _this$pagination$outc;

        this.animated = true;
        (_this$pagination$outc = this.pagination[outcoming]) === null || _this$pagination$outc === void 0 ? void 0 : _this$pagination$outc.classList.remove('active');
        gsap.set(outText, {
          zIndex: 1
        });
      },
      onComplete: () => {
        var _this$pagination$inco;

        this.animated = false;
        (_this$pagination$inco = this.pagination[incoming]) === null || _this$pagination$inco === void 0 ? void 0 : _this$pagination$inco.classList.add('active');
        gsap.set(inText, {
          zIndex: 2
        });
      }
    });
    timeline.addLabel('start');

    if (outText instanceof HTMLElement) {
      timeline.to(outText.querySelectorAll('*'), {
        y: 100,
        autoAlpha: 0,
        ease: 'power2.easeInOut',
        zIndex: 1,
        stagger: {
          from: 'end',
          each: 0.1,
          ease: 'power2.easeOut'
        }
      }, 'start');
    }

    if (inText instanceof HTMLElement) {
      timeline.fromTo(inText.querySelectorAll('*'), {
        zIndex: 2,
        y: 100
      }, {
        delay: 0.8,
        y: 0,
        autoAlpha: 1,
        ease: 'power3.easeInOut',
        duration: 1,
        stagger: {
          from: 'start',
          each: 0.1,
          ease: 'power3.easeOut'
        }
      }, 'start');
    }

    timeline.play();
  }

}, _temp);

__decorate([Watch('active')], BlockBusinessCases.prototype, "initTimeline", null);

BlockBusinessCases = __decorate([Component({
  components: {
    SliderStandard
  }
})], BlockBusinessCases);
export default BlockBusinessCases;