export default class BlockWPButtons {
  static init() {
    const buttons = document.querySelectorAll('.wp-block-button .wp-block-button__link');
    buttons.forEach(function (button) {
      // console.log(button.innerHTML)
      const text = button.innerHTML;
      const $span = document.createElement('span');
      $span.innerHTML = text;
      button.innerHTML = '';
      button.appendChild($span);
    });
  }

}