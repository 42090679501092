import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Calculator switcher
 */

import { Component } from 'vue-property-decorator';
import SwitcherComponent from '@script/mixins/SwitcherComponent';
import Graphic from '@/scripts/components/Calculator/Graphic.vue';
import CalculatorMap from '@/scripts/components/Calculator/CalculatorMap.vue';
import Selectbox from '@/scripts/components/Selectbox/Selectbox.vue';
import { EVENTS } from '@script/config/constant';
import UIkit from 'uikit';
let CalculatorSwitcher = (_temp = class CalculatorSwitcher extends SwitcherComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "active", 0);
  }

  /**
   * Mounted
   */
  mounted() {
    this.attachEventsHandlers();
  }
  /**
   * Attach event handler
   */


  attachEventsHandlers() {
    this.$events.$on(EVENTS.SELECT_UPDATE_OPTION, this.toggleSwitcher);
    UIkit.util.on(this.$el.querySelector('.tabs-content'), 'shown', event => {
      this.active = event.detail[0].index();
    });
  }

}, _temp);
CalculatorSwitcher = __decorate([Component({
  components: {
    Selectbox,
    Graphic,
    CalculatorMap
  }
})], CalculatorSwitcher);
export default CalculatorSwitcher;