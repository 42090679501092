import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Header career
 */

import { Component, Watch } from 'vue-property-decorator';
import gsap from 'gsap';
import DefaultComponent from '@script/mixins/DefaultComponent';
let HeaderCareer = (_temp = class HeaderCareer extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "active", 1);

    _defineProperty(this, "headerContent", void 0);

    _defineProperty(this, "headerVisual", void 0);

    _defineProperty(this, "thumbNav", void 0);
  }

  mounted() {
    this.attachEventsHandlers();
  }

  attachEventsHandlers() {
    this.headerContent = this.$el.querySelectorAll('.list-text li');
    this.headerVisual = this.$el.querySelectorAll('.media-content li');
    this.thumbNav = this.$el.querySelectorAll('.thumbnav li');
    /**
     * Initialize components state
     */

    if (this.headerVisual && this.headerVisual.length) {
      gsap.set(this.headerVisual, {
        autoAlpha: 0
      });
    }

    if (this.headerContent && this.headerContent.length) {
      gsap.set(this.$el.querySelectorAll('.list-text li > *'), {
        y: 200,
        autoAlpha: 0
      });
    }

    this.set(0);
  }

  set(index) {
    this.active = index;
  }

  initTimeline(incoming, outcoming) {
    const inContent = this.headerContent[incoming];
    const outContent = this.headerContent[outcoming];
    const inVisual = this.headerVisual[incoming];
    const outVisual = this.headerVisual[outcoming];
    const inThumb = this.thumbNav[incoming];
    const outThumb = this.thumbNav[outcoming];
    const timeline = gsap.timeline({
      paused: true,
      onStart: () => {
        outThumb === null || outThumb === void 0 ? void 0 : outThumb.classList.remove('active');
      },
      onComplete: () => {
        inThumb === null || inThumb === void 0 ? void 0 : inThumb.classList.add('active');
      }
    });
    timeline.addLabel('start');
    /**
     * Queue medias
     */

    if (outVisual instanceof HTMLElement) {
      timeline.to(outVisual, {
        duration: 1.2,
        autoAlpha: 0,
        ease: 'power4.easeInOut'
      }, 'start');
    }

    if (inVisual instanceof HTMLElement) {
      timeline.to(inVisual, {
        duration: 1,
        autoAlpha: 1,
        ease: 'power2.easeInOut'
      }, 'start');
    }

    if (outContent instanceof HTMLElement) {
      timeline.to(outContent.querySelectorAll('*'), {
        y: 100,
        autoAlpha: 0,
        ease: 'power2.easeInOut',
        zIndex: 1,
        stagger: {
          from: 'end',
          each: 0.1,
          ease: 'power2.easeOut'
        }
      }, 'start');
    }

    if (inContent instanceof HTMLElement) {
      timeline.fromTo(inContent.querySelectorAll('*'), {
        zIndex: 2,
        y: 100
      }, {
        delay: 0.8,
        y: 0,
        autoAlpha: 1,
        ease: 'power3.easeInOut',
        duration: 1,
        stagger: {
          from: 'start',
          each: 0.1,
          ease: 'power3.easeOut'
        }
      }, 'start');
    }

    timeline.play();
  }

}, _temp);

__decorate([Watch('active')], HeaderCareer.prototype, "initTimeline", null);

HeaderCareer = __decorate([Component], HeaderCareer);
export default HeaderCareer;