import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Header supply-change
 */

import { Component } from 'vue-property-decorator';
import gsap from 'gsap';
import VideoPlayer from '@script/components/VideoPlayer/VideoPlayer.vue';
import DefaultComponent from '@script/mixins/DefaultComponent';
import { EVENTS } from '@script/config/constant';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
let HeaderSupplyChange = (_temp = class HeaderSupplyChange extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isDesktop", void 0);

    _defineProperty(this, "timeline", void 0);
  }

  /**
   * Created
   */
  created() {
    this.setDesktop();
  }
  /**
   * Mounted
   */


  mounted() {
    this.attachEventsHandlers();
  }
  /**
   * Attach events handlers
   */


  attachEventsHandlers() {
    this.$events.$on(EVENTS.WINDOW_RESIZE, this.onWindowResize);
    this.onWindowResize();
  }
  /**
   * Set desktop
   */


  setDesktop() {
    this.isDesktop = window.innerWidth > 1024;
  }
  /**
   * Init the animation on scroll
   */


  scrollAnimationTimeline() {
    const text = this.$el.querySelector('.text');
    const playerDesktop = this.$el.querySelector('.player-desktop');
    const timeline = gsap.timeline({
      scrollTrigger: {
        id: 'supply-change',
        trigger: this.$el,
        scrub: 2,
        pin: true,
        markers: false,
        start: 'top 150px',
        end: '+=100'
      }
    });
    timeline.addLabel('start');

    if (text instanceof HTMLElement) {
      timeline.to(text, {
        y: -400,
        duration: 3,
        ease: 'power4.inOut'
      }, 'start');
    }

    if (playerDesktop instanceof HTMLElement) {
      timeline.to(playerDesktop, {
        clipPath: 'inset(0% 0% 0% round 0px)',
        duration: 4,
        ease: 'power4.inOut'
      }, 'start');
    }
  }

  onWindowResize() {
    const trigger = ScrollTrigger.getById('supply-change');

    if (this.timeline) {
      this.timeline.clear().kill();
    }

    if (trigger) {
      trigger.kill(true);
    }

    gsap.set([this.$el.querySelector('.text'), this.$el.querySelector('.player-desktop')], {
      clearProps: 'all'
    });
    this.setDesktop();

    if (this.isDesktop) {
      this.scrollAnimationTimeline();
    }
  }

}, _temp);
HeaderSupplyChange = __decorate([Component({
  components: {
    VideoPlayer
  }
})], HeaderSupplyChange);
export default HeaderSupplyChange;