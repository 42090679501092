var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "title-container",
      class: { "country-mode": _vm.currentPage === 1 }
    },
    [
      _vm.currentPage === 2
        ? _c("div", { staticClass: "location-header" }, [
            _c(
              "button",
              { staticClass: "btn-back", on: { click: _vm.onBack } },
              [_c("span"), _vm._v(" " + _vm._s(_vm.btnbackLabel) + " ")]
            ),
            _c("h3", [
              _vm._v(
                _vm._s(_vm.currentLocation ? _vm.currentLocation.titre : "")
              )
            ])
          ])
        : _vm._e(),
      _vm.currentPage !== 2
        ? _c("div", { staticClass: "locations-title" }, [
            !_vm.ismobile
              ? _c("h1", [
                  _vm._v(" " + _vm._s(_vm.baseTitle) + " "),
                  _c("span", { staticClass: "selected-country-label" }, [
                    _vm._v(_vm._s(_vm.subtitle))
                  ])
                ])
              : _c("span", [
                  _vm._v(" " + _vm._s(_vm.baseTitle) + " "),
                  _c("span", { staticClass: "selected-country-label" }, [
                    _vm._v(_vm._s(_vm.subtitle))
                  ])
                ])
          ])
        : _vm._e(),
      _vm.currentPage !== 2
        ? _c(
            "div",
            { staticClass: "select-country-wrapper" },
            [
              _c("map-selectbox", {
                attrs: {
                  options: _vm.selectBoxCountries,
                  selectedDefault: _vm.selectedCountryLabel,
                  onSelect: _vm.onSelectCountry
                }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }