import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/*
    Block posts filters
 */

import { Component, Prop } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
import { EVENTS } from '@script/config/constant';
import Selectbox from '@script/components/Selectbox/Selectbox.vue';
let PostFilters = (_temp = class PostFilters extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "activitySelectedId", 0);

    _defineProperty(this, "solutionSelectedId", 0);

    _defineProperty(this, "research", '');

    _defineProperty(this, "activityIds", void 0);

    _defineProperty(this, "solutionIds", void 0);

    _defineProperty(this, "pageUrl", void 0);

    _defineProperty(this, "search", void 0);

    _defineProperty(this, "selectedActivity", void 0);

    _defineProperty(this, "selectedSolution", void 0);
  }

  mounted() {
    this.attachEventsHandlers();

    if (this.selectedSolution) {
      this.$refs.select_solutions.updateOption(this.solutionIds.indexOf(this.selectedSolution));
    }

    if (this.selectedActivity) {
      this.$refs.select_activities.updateOption(this.activityIds.indexOf(this.selectedActivity));
    }

    if (typeof this.search !== 'undefined') {
      this.research = this.search;
    }
  }
  /**
   * Attach event Handlers
   */


  attachEventsHandlers() {
    const filterBtn = document.querySelector('.sender');
    const resetBtn = document.querySelector('.reset_btn');
    const filterOpener = document.querySelector('.filter-opener');
    const filterCloser = document.querySelector('.filters-categories .close-btn');
    const searchField = document.querySelector('#search-field');
    filterBtn === null || filterBtn === void 0 ? void 0 : filterBtn.addEventListener('click', event => {
      event.preventDefault();
      this.goToSearch();
    });
    resetBtn === null || resetBtn === void 0 ? void 0 : resetBtn.addEventListener('click', () => {
      this.resetFilters();
    });
    filterOpener === null || filterOpener === void 0 ? void 0 : filterOpener.addEventListener('click', () => {
      var _document$querySelect;

      (_document$querySelect = document.querySelector('body')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.classList.add('open-filters');
    });
    filterCloser === null || filterCloser === void 0 ? void 0 : filterCloser.addEventListener('click', () => {
      var _document$querySelect2;

      (_document$querySelect2 = document.querySelector('body')) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.classList.remove('open-filters');
    });
    this.$events.$on(EVENTS.SELECT_UPDATE_OPTION, this.onFilterSelected);
    this.$events.$on(EVENTS.SELECT_KEY_ENTER_VALIDATION, this.onKeyEnterSelectOption);
    searchField === null || searchField === void 0 ? void 0 : searchField.addEventListener('keydown', evt => {
      if ((evt === null || evt === void 0 ? void 0 : evt.code) === 'Enter') {
        this.goToSearch();
      }
    });
  }

  resetFilters() {
    this.$refs.select_solutions.updateOption(0);
    this.$refs.select_activities.updateOption(0);
    this.research = '';
    this.goToSearch();
  }

  goToSearch() {
    // console.log('search:', this.research, ', activity:', this.activitySelectedId, ', solution:', this.solutionSelectedId)
    const query = [];

    if (this.research !== '') {
      query.push('search=' + this.research);
    }

    if (this.activitySelectedId !== 0) {
      query.push('act=' + this.activitySelectedId);
    }

    if (this.solutionSelectedId !== 0) {
      query.push('sol=' + this.solutionSelectedId);
    }

    if (query.length) {
      window.location.href = this.pageUrl + '/?' + query.join('&');
    } else {
      window.location.href = this.pageUrl;
    }
  }

  onFilterSelected(index, selectbox) {
    console.log('id:', selectbox.$el.id);

    if (selectbox.$el.id === 'select_solutions') {
      console.log('index:', index, 'solutionSelectedId:', this.solutionIds[index]);
      this.solutionSelectedId = this.solutionIds[index];
    } else {
      this.activitySelectedId = this.activityIds[index];
    }
  }

  onKeyEnterSelectOption() {
    this.goToSearch();
  }

}, _temp);

__decorate([Prop()], PostFilters.prototype, "activityIds", void 0);

__decorate([Prop()], PostFilters.prototype, "solutionIds", void 0);

__decorate([Prop()], PostFilters.prototype, "pageUrl", void 0);

__decorate([Prop()], PostFilters.prototype, "search", void 0);

__decorate([Prop()], PostFilters.prototype, "selectedActivity", void 0);

__decorate([Prop()], PostFilters.prototype, "selectedSolution", void 0);

PostFilters = __decorate([Component({
  components: {
    Selectbox
  }
})], PostFilters);
export default PostFilters;