import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
import Events, { LOCATIONS_EVENTS } from '@script/components/BlockLocations/BlockLocationsEvents';
let LocationsListCountries = (_temp = class LocationsListCountries extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "countries", void 0);
  }

  onSelect(country) {
    Events.dispatchEvent(LOCATIONS_EVENTS.ON_SELECT_COUNTRY, country);
  }

}, _temp);

__decorate([Prop()], LocationsListCountries.prototype, "countries", void 0);

LocationsListCountries = __decorate([Component], LocationsListCountries);
export default LocationsListCountries;