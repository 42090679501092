import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import Mapbox from 'mapbox-gl';
import { MglMap, MglMarker, MglNavigationControl } from 'v-mapbox';
import DefaultComponent from '@script/mixins/DefaultComponent';
import TitlesWrapper from '@script/components/BlockLocations/TitlesWrapper.vue';
import ContentWrapper from '@script/components/BlockLocations/ContentWrapper.vue';
import MapMarker from '@script/components/BlockLocations/MapMarker.vue';
import Events, { LOCATIONS_EVENTS } from '@script/components/BlockLocations/BlockLocationsEvents';
import { EVENTS, MAPBOX } from '@script/config/constant';
let currentMapRef = null;
let BlockLocations = (_temp = class BlockLocations extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "BREAKPOINT_TABLET", 991);

    _defineProperty(this, "LEFT_PANEL_WIDTH", 450);

    _defineProperty(this, "MAXZOOM", 16);

    _defineProperty(this, "MINZOOM", 1);

    _defineProperty(this, "isMapLoaded", false);

    _defineProperty(this, "accessToken", MAPBOX.TOKEN);

    _defineProperty(this, "mapStyle", MAPBOX.STYLE);

    _defineProperty(this, "mapbox", null);

    _defineProperty(this, "countries", []);

    _defineProperty(this, "currentBounds", {
      top: 200,
      right: 200,
      bottom: 200,
      left: 650
    });

    _defineProperty(this, "currentZoom", 1);

    _defineProperty(this, "countryLocations", []);

    _defineProperty(this, "mapActions", null);

    _defineProperty(this, "translations", void 0);

    _defineProperty(this, "mapData", void 0);
  }

  created() {
    this.mapbox = Mapbox;
    Vue.$mapTranslations = this.translations;
  }

  buildCountries() {
    if (this.mapData) {
      console.log('this.mapData: ', this.mapData); // Set countries list

      let itemCount = 0;
      this.countries = this.mapData.filter(loc => Boolean(loc.titre) && loc.location && Boolean(loc.location.lat) && Boolean(loc.location.long) && Boolean(loc.country)).reduce((accu, item) => {
        if (!accu.find(({
          label
        }) => item.country === label)) {
          itemCount++;
          accu.push({
            id: itemCount,
            label: item.country,
            slug: item.countrySlug
          });
        }

        return accu;
      }, [{
        id: itemCount,
        label: this.mapData[0].country,
        slug: this.mapData[0].countrySlug
      }]).sort(({
        label: c1
      }, {
        label: c2
      }) => c1.localeCompare(c2));
    }
  }

  fitBounds(coordinates) {
    this.mapActions.fitBounds(coordinates, {
      padding: { ...this.currentBounds
      }
    });
  }

  onLocationsListUpdate(locs) {
    // Build coordinates for markers
    const pointTL = locs.reduce((accu, item) => {
      const long = accu[0] < item.location.long ? accu[0] : item.location.long;
      const lat = accu[1] > item.location.lat ? accu[1] : item.location.lat;
      return [long, lat];
    }, [locs[0].location.long, locs[0].location.lat]);
    const pointBR = locs.reduce((accu, item) => {
      const long = accu[0] > item.location.long ? accu[0] : item.location.long;
      const lat = accu[1] < item.location.lat ? accu[1] : item.location.lat;
      return [long, lat];
    }, [locs[0].location.long, locs[0].location.lat]);
    this.countryLocations = locs;
    this.fitBounds([pointTL, pointBR]);
  }

  onSelectLocation(loc) {
    this.fitBounds([[loc.location.long, loc.location.lat], [loc.location.long, loc.location.lat]]);
  } // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types


  onMapLoaded(event) {
    this.onWindowResize();
    this.mapActions = event.component.actions;
    this.isMapLoaded = true;
    currentMapRef = event.map;
    Events.setIsMapLoaded(true);
  } // Resize


  onWindowResize() {
    setTimeout(() => {
      const container = document.querySelector('#map-fmlogistics');

      if (container) {
        const bounds = container.getBoundingClientRect();

        if (bounds.width) {
          const basePadding = bounds.width * 0.13;
          this.currentBounds = {
            top: basePadding,
            right: basePadding,
            bottom: basePadding,
            left: bounds.width < this.BREAKPOINT_TABLET ? basePadding : this.LEFT_PANEL_WIDTH + basePadding
          };
        }
      }
    }, 250);
  } // Marker clikc handler
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types


  onClickMarker(params) {
    // find ILocation by long/lat
    try {
      const markerComponent = params.component.$slots.marker[0].child;
      const locID = markerComponent.getData();
      const loc = this.countryLocations.find(({
        id
      }) => {
        return locID === id;
      });

      if (loc) {
        Events.dispatchEvent(LOCATIONS_EVENTS.ON_SELECT_LOCATION, loc);
      } else {
        throw new Error('Location not found');
      }
    } catch (e) {
      console.log(e);
    }
  } // Zoom


  async onZoom(level) {
    if (currentMapRef) {
      this.currentZoom = currentMapRef.getZoom();
    }

    let targetZoom = this.currentZoom + level;
    targetZoom = targetZoom < this.MINZOOM ? this.MINZOOM : targetZoom > this.MAXZOOM ? this.MAXZOOM : targetZoom;
    await this.mapActions.zoomTo(targetZoom);
    this.currentZoom = targetZoom;
  }

  mounted() {
    this.buildCountries(); // Events

    Events.$on(LOCATIONS_EVENTS.ON_LOCATIONS_LIST_UPDATE, this.onLocationsListUpdate);
    Events.$on(LOCATIONS_EVENTS.ON_SELECT_LOCATION, this.onSelectLocation);
    this.$events.$on(EVENTS.WINDOW_RESIZE, this.onWindowResize);
    console.log('MOUNTED--> Main');
  }

}, _temp);

__decorate([Prop()], BlockLocations.prototype, "translations", void 0);

__decorate([Prop()], BlockLocations.prototype, "mapData", void 0);

BlockLocations = __decorate([Component({
  components: {
    MglMap,
    MglNavigationControl,
    MglMarker,
    MapMarker,
    TitlesWrapper,
    ContentWrapper
  }
})], BlockLocations);
export default BlockLocations;