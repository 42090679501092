export default class BlockFaq {
  static init() {
    const blockFaqAll = document.querySelectorAll('.block-faq');
    blockFaqAll.forEach(function (block) {
      const seeMoreBtn = block.querySelector('.cta-show-more');
      seeMoreBtn === null || seeMoreBtn === void 0 ? void 0 : seeMoreBtn.addEventListener('click', function (event) {
        event.preventDefault();
        block === null || block === void 0 ? void 0 : block.classList.toggle('show-all');
      });
    });
  }

}