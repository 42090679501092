var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "locations-list" }, [
    _c("div", { staticClass: "locations-list__header" }, [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _vm.selectboxOptions.length
        ? _c(
            "div",
            { staticClass: "selectebox-wrapper" },
            [
              _c("map-selectbox", {
                attrs: {
                  options: _vm.selectboxOptions,
                  selectedDefault: _vm.defaultSelectboxLabel,
                  onSelect: _vm.onSelectActivity
                }
              })
            ],
            1
          )
        : _vm._e()
    ]),
    _c("div", { staticClass: "inner-wrapper" }, [
      _vm.currentLocations && _vm.currentLocations.length
        ? _c(
            "ul",
            _vm._l(_vm.currentLocations, function(loc, index) {
              return _c("li", { key: index }, [
                _c(
                  "a",
                  {
                    staticClass: "location-item",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onSelectLocation(loc)
                      }
                    }
                  },
                  [
                    _c("h4", [_vm._v(_vm._s(loc.titre))]),
                    _c("div", { staticClass: "adresses" }, [
                      loc.address
                        ? _c("p", { staticClass: "adress" }, [
                            _vm._v(_vm._s(loc.address))
                          ])
                        : _vm._e(),
                      loc.address2
                        ? _c("p", { staticClass: "adress" }, [
                            _vm._v(_vm._s(loc.address2))
                          ])
                        : _vm._e(),
                      loc.zipCode || loc.region
                        ? _c("p", [
                            loc.zipCode
                              ? _c("span", [_vm._v(_vm._s(loc.zipCode))])
                              : _vm._e(),
                            loc.region
                              ? _c("span", [_vm._v(_vm._s(" " + loc.region))])
                              : _vm._e()
                          ])
                        : _vm._e(),
                      loc.tel
                        ? _c("p", { staticClass: "adress" }, [
                            _vm._v(_vm._s(loc.tel))
                          ])
                        : _vm._e()
                    ]),
                    _c("button", [
                      _c("span"),
                      _vm._v(" " + _vm._s(_vm.contactUsLabel) + " ")
                    ])
                  ]
                )
              ])
            }),
            0
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }