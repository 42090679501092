import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
import { EVENTS } from '@/scripts/config/constant';
import Flags from '@script/data/flags.json';
import Choices from 'choices.js';
let Countries = (_temp = class Countries extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "choices", void 0);

    _defineProperty(this, "lang", void 0);
  }

  /**
   * Mounted
   */
  mounted() {
    this.createChoices();
    this.attachEventHandlers();
  }
  /**
   * Event handlers
   */


  attachEventHandlers() {
    this.choices.passedElement.element.addEventListener('choice', item => {
      const {
        choice
      } = item.detail;
      this.$events.$emit(EVENTS.CALCULATOR_FORM_UPDATE_COUNTRY_OPTIONS, {
        id: this.$attrs.id,
        label: choice.label,
        value: choice.value
      });
    });
  }
  /**
   * Create Choices
   */


  createChoices() {
    const OPTIONS = {
      allowHTML: true,
      itemSelectText: '',
      callbackOnCreateTemplates: function (template) {
        return {
          item: ({
            classNames
          }, data) => {
            const SELECT_STATE = data.highlighted ? classNames.highlightedState : classNames.itemSelectable;
            const ACTIVE = data.active ? 'aria-selected="true"' : '';
            const DISABLE = data.disabled ? 'aria-disabled="true"' : '';
            return template(`<div class="${classNames.item} ${SELECT_STATE}" data-item data-id="${data.id}" data-value="${data.value}" ${ACTIVE} ${DISABLE}>
                            ${data.label}
                            </div>`);
          },
          choice: ({
            classNames,
            itemSelectText
          }, data) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const SELECT_TEXT = itemSelectText;
            const SELECT_STATE = data.disabled ? classNames.itemDisabled : classNames.itemSelectable;
            const DISABLE = data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable';
            const GROUP_ID = data.groupId > 0 ? 'role="treeitem"' : 'role="option"';
            const CODE = `${data.value}`.split('|')[0];
            const FLAG = Flags[CODE];
            return template(`<div class="${classNames.item} ${classNames.itemChoice} ${SELECT_STATE}" data-select-text="${SELECT_TEXT}" data-choice ${DISABLE} data-id="${data.id}" data-value="${data.value}" ${GROUP_ID}>
                            <span class="flag">
                            <img src="${FLAG}"/>
                            </span>
                            ${data.label}
                            </div>`);
          }
        };
      }
    };
    this.choices = new Choices(this.$el, OPTIONS);
    this.choices.setChoices(this.getCountries);
  }
  /**
   * Get countries from API
   * @see https://github.com/stefangabos/world_countries
   */


  getCountries() {
    const RESPONSE = fetch(`https://cdn.jsdelivr.net/npm/world_countries_lists@latest/data/countries/${this.lang}/countries.json`);
    return RESPONSE.then(res => {
      if (!res.ok) {
        return fetch(`https://cdn.jsdelivr.net/npm/world_countries_lists@latest/data/countries/en/countries.json`).then(resFallback => resFallback.json());
      }

      return res.json();
    }).then(data => data.map(country => {
      return {
        value: `${country.alpha2}|${country.name}`,
        label: country.name,
        id: country.id
      };
    }));
  }

}, _temp);

__decorate([Prop()], Countries.prototype, "lang", void 0);

Countries = __decorate([Component], Countries);
export default Countries;