import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let MapMarker = (_temp = class MapMarker extends Vue {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "customData", void 0);
  }

  getData() {
    return this.customData;
  }

}, _temp);

__decorate([Prop()], MapMarker.prototype, "customData", void 0);

MapMarker = __decorate([Component], MapMarker);
export default MapMarker;