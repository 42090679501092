import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";
export default class GuidedVisit {
  static init() {
    GuidedVisit.$wrapper = document.querySelector('.visite-guidee');

    if (GuidedVisit.$wrapper) {
      GuidedVisit.$visitWrapper = GuidedVisit.$wrapper.querySelector('.visite-wrapper');
      GuidedVisit.$choices = GuidedVisit.$wrapper.querySelector('.choices');
      GuidedVisit.$close = GuidedVisit.$wrapper.querySelector('.close');
      GuidedVisit.$toggleVisite = GuidedVisit.$wrapper.querySelector('a.toggle-visite');
      GuidedVisit.$rootTitle = GuidedVisit.$wrapper.querySelector('h2.title');
      GuidedVisit.data = JSON.parse(GuidedVisit.$wrapper.getAttribute('data-choices'));
      GuidedVisit.roots = JSON.parse(GuidedVisit.$wrapper.getAttribute('data-roots'));
      console.log(GuidedVisit.data);
      console.log(GuidedVisit.roots);
      GuidedVisit.rootTitle = GuidedVisit.$rootTitle.innerHTML;
      GuidedVisit.initClicks();
      GuidedVisit.createRoots();
      GuidedVisit.$wrapper.classList.add('open');
      window.addEventListener('resize', () => {
        GuidedVisit.updateChoicesHeight();
      }); // console.log(GuidedVisit.$visitWrapper.querySelector('ul.current'))
    }
  }

  static initClicks() {
    GuidedVisit.$choices.addEventListener('click', event => {
      const key = event.target.getAttribute('data-name');
      event.preventDefault();

      if (key) {
        GuidedVisit.createNext(key);
      }
    });
    GuidedVisit.$close.addEventListener('click', () => {
      GuidedVisit.close();
    });
    GuidedVisit.$toggleVisite.addEventListener('click', event => {
      event.preventDefault();

      if (GuidedVisit.$visitWrapper.classList.contains('open')) {
        GuidedVisit.close();
      } else {
        GuidedVisit.open();
      }
    });
  }

  static open() {
    var _document$querySelect;

    GuidedVisit.createRoots();
    GuidedVisit.$visitWrapper.classList.add('open');
    (_document$querySelect = document.querySelector('body')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.classList.add('guided-visit-open');
  }

  static close() {
    var _document$querySelect2;

    GuidedVisit.$visitWrapper.classList.remove('open');
    (_document$querySelect2 = document.querySelector('body')) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.classList.remove('guided-visit-open');
  }

  static createRoots() {
    const $ul = GuidedVisit.$visitWrapper.querySelector('ul.current');

    while ($ul.firstChild) {
      $ul.removeChild($ul.lastChild);
    }

    GuidedVisit.$rootTitle.innerHTML = GuidedVisit.rootTitle;

    for (const key in GuidedVisit.roots) {
      const $li = document.createElement('li');
      const $a = document.createElement('a');
      const $span = document.createElement('span');
      const $text = document.createTextNode(key);
      $span.appendChild($text);
      $a.href = '#';
      $a.className = 'cta cta-green';
      $a.setAttribute('data-name', key);
      $a.appendChild($span);
      $li.appendChild($a);
      $ul.appendChild($li);
    }

    GuidedVisit.updateChoicesHeight();
  }

  static updateChoicesHeight() {
    setTimeout(() => {
      var _GuidedVisit$$choices;

      GuidedVisit.$choices.style.height = ((_GuidedVisit$$choices = GuidedVisit.$choices.querySelector('ul.current')) === null || _GuidedVisit$$choices === void 0 ? void 0 : _GuidedVisit$$choices.offsetHeight) + 'px';
    }, 200);
  }

  static createNext(key) {
    if (GuidedVisit.data[key].children) {
      const children = GuidedVisit.data[key].children;
      const $nextUl = GuidedVisit.$visitWrapper.querySelector('ul.next');

      while ($nextUl.firstChild) {
        $nextUl.removeChild($nextUl.lastChild);
      }

      for (const key in children) {
        const $li = document.createElement('li');
        const $a = document.createElement('a');
        const $span = document.createElement('span');
        const $text = document.createTextNode(children[key]);
        $span.appendChild($text);
        $a.href = '#';
        $a.className = 'cta cta-green';
        $a.setAttribute('data-name', children[key]);
        $a.appendChild($span);
        $li.appendChild($a);
        $nextUl.appendChild($li);
      }

      setTimeout(() => {
        GuidedVisit.gotoNext(key);
      }, 100);
    } else {
      location.href = GuidedVisit.data[key].lien;
    }
  }

  static gotoNext(key) {
    const $current = GuidedVisit.$visitWrapper.querySelector('ul.current');
    const $next = GuidedVisit.$visitWrapper.querySelector('ul.next');
    const height = Math.max($current.offsetHeight, $next.offsetHeight);
    GuidedVisit.$rootTitle.innerHTML = GuidedVisit.data[key].titre_enfant;
    GuidedVisit.$choices.style.height = height + 'px';
    $current.classList.remove('current');
    $current.classList.add('previous');
    $next.classList.remove('next');
    $next.classList.add('current');
    GuidedVisit.updateChoicesHeight();
    setTimeout(() => {
      $current.classList.remove('previous');
      $current.classList.add('next');
    }, 600);
  }

}

_defineProperty(GuidedVisit, "$wrapper", void 0);

_defineProperty(GuidedVisit, "$visitWrapper", void 0);

_defineProperty(GuidedVisit, "$choices", void 0);

_defineProperty(GuidedVisit, "$close", void 0);

_defineProperty(GuidedVisit, "$toggleVisite", void 0);

_defineProperty(GuidedVisit, "$rootTitle", void 0);

_defineProperty(GuidedVisit, "data", void 0);

_defineProperty(GuidedVisit, "roots", void 0);

_defineProperty(GuidedVisit, "rootTitle", void 0);