var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "slider slider-standard swiper-container" },
    [
      _vm._t("default"),
      _vm._m(0),
      _vm._m(1),
      _c("div", { staticClass: "swiper-pagination" })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav-prev animated animated-scale" }, [
      _c("i", { staticClass: "icon icon-arrow-stroke-white" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav-next animated animated-scale" }, [
      _c("i", { staticClass: "icon icon-arrow-stroke-white" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }