var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "location-view" }, [
    _vm.location
      ? _c("div", { staticClass: "inner-wrapper" }, [
          _vm.schedule && _vm.schedule.length
            ? _c("div", { staticClass: "section" }, [
                _c("h5", [_vm._v(_vm._s(_vm.hoursOfOpeningLabel))]),
                _c(
                  "div",
                  { staticClass: "hours-opening-wrapper" },
                  _vm._l(_vm.schedule, function(scheduleDay, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "day",
                        class: { closed: !scheduleDay.isOpen }
                      },
                      [
                        _c("h6", [
                          _vm._v(_vm._s(scheduleDay.day.substr(0, 3)))
                        ]),
                        _c("div", { staticClass: "schedule" }, [
                          _c("p", {
                            domProps: {
                              innerHTML: _vm._s(scheduleDay.openingTime)
                            }
                          }),
                          _c("p", {
                            domProps: {
                              innerHTML: _vm._s(scheduleDay.closingTime)
                            }
                          })
                        ])
                      ]
                    )
                  }),
                  0
                )
              ])
            : _vm._e(),
          _vm.location.services && _vm.location.services.length
            ? _c(
                "div",
                { staticClass: "section services" },
                [
                  _c("h5", [_vm._v(_vm._s(_vm.servicesLabel))]),
                  _vm._l(_vm.location.services, function(service, index) {
                    return _c("p", { key: index }, [
                      _vm._v(" " + _vm._s(service) + " ")
                    ])
                  })
                ],
                2
              )
            : _vm._e(),
          _c("div", { staticClass: "section addresses" }, [
            _vm.location.address
              ? _c("p", { staticClass: "top-address" }, [
                  _vm._v(_vm._s(_vm.location.address))
                ])
              : _vm._e(),
            _vm.location.address2
              ? _c("p", [_vm._v(_vm._s(_vm.location.address2))])
              : _vm._e(),
            _vm.location.zipCode || _vm.location.region
              ? _c("p", [
                  _vm.location.zipCode
                    ? _c("span", [_vm._v(_vm._s(_vm.location.zipCode))])
                    : _vm._e(),
                  _vm.location.region
                    ? _c("span", [_vm._v(_vm._s(" " + _vm.location.region))])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.location.tel
              ? _c("p", [_vm._v(_vm._s(_vm.location.tel))])
              : _vm._e(),
            _c("button", { on: { click: _vm.gotoMapDirection } })
          ]),
          _vm.location.email && _vm.isValidEmail(_vm.location.email)
            ? _c(
                "a",
                {
                  staticClass: "cta cta-green",
                  attrs: { href: "mailto:" + _vm.location.email }
                },
                [_c("span", [_vm._v(_vm._s(_vm.contactUsLabel))])]
              )
            : _vm._e(),
          _vm.location.email && !_vm.isValidEmail(_vm.location.email)
            ? _c(
                "a",
                {
                  staticClass: "cta cta-green",
                  attrs: { href: _vm.location.email }
                },
                [_c("span", [_vm._v(_vm._s(_vm.contactUsLabel))])]
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }