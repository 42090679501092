import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Block timeline
 */

import { Component, Watch } from 'vue-property-decorator';
import gsap from 'gsap';
import Selectbox from '@script/components/Selectbox/Selectbox.vue';
import SeeMore from '@script/components/SeeMore/SeeMore.vue';
import DefaultComponent from '@script/mixins/DefaultComponent';
import { EVENTS } from '@script/config/constant';
import { getMaxHeight } from '@script/utils/utils'; // import { ScrollTrigger } from 'gsap/ScrollTrigger'

let BlockTimeline = (_temp = class BlockTimeline extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "active", -1);

    _defineProperty(this, "offset", 0);

    _defineProperty(this, "dateList", void 0);

    _defineProperty(this, "step", void 0);

    _defineProperty(this, "animated", false);

    _defineProperty(this, "dates", void 0);

    _defineProperty(this, "timelineList", void 0);
  }

  mounted() {
    this.attachEventsHandlers();
  }

  attachEventsHandlers() {
    this.timelineList = this.$el.querySelectorAll('.timeline');
    this.dateList = this.$el.querySelector('.date-list');
    this.dates = this.dateList.querySelectorAll('li');
    this.step = window.innerWidth > 1023 ? 200 : 150;
    /* const length = this.timelineList.length - 1
    const snap = 1 / length */

    this.$events.$on(EVENTS.SELECT_UPDATE_OPTION, this.set);
    this.$events.$on(EVENTS.WINDOW_RESIZE, this.setHeight);
    /* ScrollTrigger.create(
        {
            id: 'history',
            trigger: '.block-history--wrapper',
            start: 'top 20%',
            end: 'bottom 20%',
            pin: true,
            markers: true,
            anticipatePin: 1,
            snap: {
                snapTo: this.directionalSnap(snap),
                duration: {
                    min: 0.2,
                    max: 3
                },
                ease: 'power4'
            },
            onLeave: () => {
                console.log('leave')
                this.set(length)
            },
            onLeaveBack: () => {
                console.log('leave back')
                this.set(0)
            },
            onSnapComplete: ({ progress }) => {
                const index = progress * 10
                 this.set(index)
            }
        }
    ) */

    this.set(0);
    this.setHeight();
    this.dateTimeline();
  }
  /**
   * Set active
   * @param index
   */


  set(index) {
    if (!this.animated) {
      this.active = index;
    }
  }

  initTimeline(incoming, outcoming) {
    // const dateWrapper = this.$el.querySelector('.date-list')
    const incomeContent = this.timelineList[incoming];
    const outcomeContent = this.timelineList[outcoming];
    const incomeDate = this.dates[incoming];
    const outcomeDate = this.dates[outcoming];
    const direction = outcoming < incoming ? -1 : 1; // this.setOffset()

    const timeline = gsap.timeline({
      onStart: () => {
        this.animated = true;
        outcomeContent === null || outcomeContent === void 0 ? void 0 : outcomeContent.classList.remove('active');
        outcomeDate === null || outcomeDate === void 0 ? void 0 : outcomeDate.classList.remove('active');
      },
      onComplete: () => {
        this.animated = false;
        incomeContent === null || incomeContent === void 0 ? void 0 : incomeContent.classList.add('active');
        incomeDate === null || incomeDate === void 0 ? void 0 : incomeDate.classList.add('active');
      }
    });

    if (outcomeContent instanceof HTMLElement) {
      timeline.to([outcomeContent.querySelector('.subtitle'), outcomeContent.querySelector('.editor'), outcomeContent.querySelector('.link--wrapper'), outcomeContent.querySelector('.visual')], {
        y: 300 * direction,
        autoAlpha: 0,
        duration: 1,
        ease: 'power4.easeInOut',
        stagger: {
          from: direction === 1 ? 'end' : 'start',
          each: 0.1,
          ease: 'power4.easeOut'
        }
      }, 0);
    }

    if (incomeContent instanceof HTMLElement) {
      timeline.fromTo([incomeContent.querySelector('.subtitle'), incomeContent.querySelector('.editor'), incomeContent.querySelector('.link--wrapper'), incomeContent.querySelector('.visual')], {
        y: -300 * direction
      }, {
        delay: 0.2,
        y: 0,
        autoAlpha: 1,
        duration: 1,
        ease: 'power4.easeInOut',
        stagger: {
          from: direction === 1 ? 'end' : 'start',
          each: 0.1,
          ease: 'power4.easeOut'
        }
      }, 0);
    }
    /* if (dateWrapper instanceof HTMLElement) {
        timeline.to(
            dateWrapper,
            {
                y: this.offset,
                duration: 1,
                ease: 'power3'
            }
        )
    } */

  }
  /**
   * Set Timeline Content Height
   */


  setHeight() {
    const height = getMaxHeight(this.timelineList);
    const timelineWrapper = this.$el.querySelector('.timeline-content');

    if (timelineWrapper instanceof HTMLElement) {
      timelineWrapper.style.height = `${height}px`;
    }
  }

  setOffset() {
    this.offset = this.step * this.active * -1;
  }

  directionalSnap(increment) {
    const snapFunc = gsap.utils.snap(increment);
    return (raw, self) => {
      const n = snapFunc(raw);
      return Math.abs(n - raw) < 1e-4 || n < raw === self.direction < 0 ? n : self.direction < 0 ? n - increment : n + increment;
    };
  }

  dateTimeline() {
    const dateWrapper = this.$el.querySelector('.date-list');
    const timeline = gsap.timeline();
    const length = this.timelineList.length - 1;
    const snap = 1 / length;

    if (dateWrapper instanceof HTMLElement) {
      timeline.to(dateWrapper, {
        yPercent: -100,
        duration: 1,
        ease: 'none',
        scrollTrigger: {
          id: 'history',
          trigger: '.block-history--wrapper',
          start: 'top 20%',
          end: 'bottom 20%',
          pin: true,
          // markers: true,
          anticipatePin: 1,
          scrub: 0.5,
          snap: this.directionalSnap(snap),
          onLeave: () => {
            console.log('leave');
            this.set(length);
          },
          onLeaveBack: () => {
            console.log('leave back');
            this.set(0);
          },
          onSnapComplete: ({
            progress
          }) => {
            let index = progress * 10 + 1;

            if (index > length) {
              index = length;
            }

            this.set(index);
          }
        }
      });
    }
  }

}, _temp);

__decorate([Watch('active')], BlockTimeline.prototype, "initTimeline", null);

BlockTimeline = __decorate([Component({
  components: {
    Selectbox,
    SeeMore
  }
})], BlockTimeline);
export default BlockTimeline;