import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Header
 */

import { Component } from 'vue-property-decorator';
import gsap from 'gsap';
import UIkit from 'uikit';
import DefaultComponent from '@script/mixins/DefaultComponent';
import { EVENTS } from '@script/config/constant';
import Cookies from 'js-cookie';
let MainHeader = (_temp = class MainHeader extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "mainMenuTimeline", void 0);

    _defineProperty(this, "subMenuTimeline", void 0);

    _defineProperty(this, "mobileMenuOpen", false);

    _defineProperty(this, "mobileSubMenuOpen", false);

    _defineProperty(this, "subMenuElement", void 0);
  }

  /**
   * Mounted
   */
  mounted() {
    this.componentsBehaviors();
    this.drawMainMenuTimeline();
    this.attachEventHandlers();
    this.desktopMouseEvents();
    this.memorizeDisablingBanner();
  }
  /**
   * Attach event handlers
   */


  attachEventHandlers() {
    const toggleMenu = this.$el.querySelector('.toggle-menu'); // toggle menu

    if (toggleMenu instanceof HTMLElement) {
      toggleMenu.addEventListener('click', this.toggleMainMenu);
    }

    this.subMenuElement = this.$el.querySelectorAll('.nav-mobile ul .has-submenu'); // submenu toggler

    if (this.subMenuElement && this.subMenuElement.length) {
      this.subMenuElement.forEach((element, index) => {
        const showSubmenu = element.querySelector('.submenu--inner > a');
        const hideSubmenu = element.querySelector('.toggle-submenu');

        if (showSubmenu instanceof HTMLElement) {
          showSubmenu.addEventListener('click', event => {
            event.preventDefault();
            this.setSubMenuActive(index);
            this.showSubMenu();
          });
        }

        if (hideSubmenu instanceof HTMLElement) {
          hideSubmenu.addEventListener('click', () => {
            this.hideSubMenu();
          });
        }
      });
    } // Toggle submenu


    this.$events.$on(EVENTS.HEADER_SUBMENU_TOGGLE, this.drawSubMenuTimeline); // windows resize

    this.$events.$on(EVENTS.WINDOW_RESIZE, this.onWindowsResize); // Window scroll

    this.$events.$on(EVENTS.WINDOW_SCROLL, this.onWindowsScroll);
    this.searchTimeline();
  }
  /**
   * Toggle menu
   */


  toggleMainMenu() {
    if (this.mainMenuTimeline) {
      if (this.mobileSubMenuOpen) {
        this.hideSubMenu();
      }

      this.mobileMenuOpen = !this.mobileMenuOpen;
      this.mainMenuTimeline.duration(1).reversed(!this.mobileMenuOpen);
    }
  }
  /**
   * Set submenu active
   * @param index
   */


  setSubMenuActive(index) {
    this.$events.$emit(EVENTS.HEADER_SUBMENU_TOGGLE, index);
  }
  /**
   * Show submenu
   */


  showSubMenu() {
    if (this.subMenuTimeline) {
      this.mobileSubMenuOpen = true;
      this.subMenuTimeline.duration(1).reversed(!this.mobileSubMenuOpen);
    }
  }
  /**
   * Hide submenu
   */


  hideSubMenu() {
    if (this.subMenuTimeline) {
      this.mobileSubMenuOpen = false;
      this.subMenuTimeline.duration(1).reversed(!this.mobileSubMenuOpen);
    }
  }
  /**
   * Init Main Menu timeline
   */


  drawMainMenuTimeline() {
    this.mainMenuTimeline = gsap.timeline({
      paused: true,
      yoyo: true,
      onStart: () => {
        document.body.classList.add('main-menu-open');
      },
      onReverseComplete: () => {
        document.body.classList.remove('main-menu-open');
      }
    });
    this.mainMenuTimeline.to('.nav-mobile--inner', {
      x: 0,
      duration: 1,
      ease: 'power2.out'
    }).from('.nav-mobile--inner .nav-primary--list > li .submenu--inner > a', {
      duration: 1,
      x: window.innerWidth < 1024 ? -100 : 0,
      autoAlpha: window.innerWidth < 1024 ? 0 : 1,
      ease: 'power2.out',
      yoyo: true,
      clearProps: 'all',
      stagger: 0.2
    }, 1).reverse();
  }
  /**
   * Init Sub Menu timeline
   */


  drawSubMenuTimeline(index) {
    var _this$subMenuElement;

    const currentSubmenu = (_this$subMenuElement = this.subMenuElement) === null || _this$subMenuElement === void 0 ? void 0 : _this$subMenuElement.item(index);
    const submenu = currentSubmenu === null || currentSubmenu === void 0 ? void 0 : currentSubmenu.querySelector('.submenu');
    this.subMenuTimeline = gsap.timeline({
      paused: true,
      yoyo: true,
      onStart: () => {
        document.body.classList.add('sub-menu-open');
      },
      onReverseComplete: () => {
        document.body.classList.remove('sub-menu-open');
      }
    });

    if (submenu instanceof HTMLElement) {
      this.subMenuTimeline.to(submenu, {
        x: 0,
        duration: 1,
        ease: 'power2.out'
      }).from([submenu.querySelector('.submenu-parent'), submenu.querySelectorAll('.submenu-categorie--title'), submenu.querySelectorAll('.submenu-categorie--list li')], {
        duration: 1,
        x: window.innerWidth < 1024 ? -100 : 0,
        autoAlpha: window.innerWidth < 1024 ? 0 : 1,
        ease: 'power2.out',
        yoyo: true,
        clearProps: 'all',
        stagger: 0.2
      }, 1).reverse();
    }
  }
  /**
   * Window resize
   */


  onWindowsResize() {
    this.mobileMenuOpen = false;
    this.mobileSubMenuOpen = false;

    if (this.mainMenuTimeline) {
      this.mainMenuTimeline.clear().kill();
    }

    document.body.classList.remove('main-menu-open');
    document.body.classList.remove('sub-menu-open');
    gsap.set(['.nav-mobile--inner', '.nav-mobile .submenu'], {
      clearProps: 'all'
    });
    this.drawMainMenuTimeline();
  }
  /**
   * Window scroll
   */


  onWindowsScroll({
    direction,
    position
  }) {
    const isOffset = position * -1 > this.$el.clientHeight;

    if (direction === 'up') {
      document.body.classList.remove('scroll-menu');
    } else {
      isOffset && document.body.classList.add('scroll-menu');
    }
  }
  /**
   * Set animation on scrollspy
   */


  componentsBehaviors() {
    const pageTop = document.getElementById('page-top-trigger');

    if (pageTop && window.scrollY > 50) {
      document.body.classList.add('sticky-menu');
    }

    UIkit.util.on(pageTop, 'outview', () => {
      document.body.classList.add('sticky-menu');
    });
    UIkit.util.on(pageTop, 'inview', () => {
      document.body.classList.remove('sticky-menu');
    });
  }

  desktopMouseEvents() {
    const submenu = this.$el.querySelectorAll('.nav-desktop ul .has-submenu');
    submenu.forEach(element => {
      element.addEventListener('mouseenter', () => {
        document.body.classList.add('sub-menu-desktop-open');
      });
      element.addEventListener('mouseleave', () => {
        document.body.classList.remove('sub-menu-desktop-open');
      });
    });
  }

  searchTimeline() {
    const linkSearch = this.$el.querySelector('.nav-desktop .link-search');
    const clearForm = this.$el.querySelector('.nav-desktop .header-search .field-clear');
    const textForm = this.$el.querySelector('.nav-desktop .header-search .field-search');
    const linkParent = linkSearch === null || linkSearch === void 0 ? void 0 : linkSearch.parentElement;

    if (linkSearch instanceof HTMLElement) {
      linkSearch.addEventListener('click', event => {
        event.preventDefault();
        linkParent === null || linkParent === void 0 ? void 0 : linkParent.classList.add('show-form');
      });
    }

    if (clearForm instanceof HTMLElement) {
      clearForm.addEventListener('click', () => {
        linkParent === null || linkParent === void 0 ? void 0 : linkParent.classList.remove('show-form');
      });
    }

    if (textForm instanceof HTMLElement) {
      textForm.addEventListener('focusout', () => {
        linkParent === null || linkParent === void 0 ? void 0 : linkParent.classList.remove('show-form');
      });
    }
  }

  memorizeDisablingBanner() {
    const headerBanner = this.$el.querySelector('.header-banner');

    if (headerBanner instanceof HTMLElement) {
      const closeBanner = headerBanner.querySelector('.close');
      const bannerIdentifier = headerBanner.getAttribute('data-identifier');
      const HeaderBannerTitle = headerBanner.querySelector('.title');
      const HeaderTextParagraph = headerBanner.querySelector('.text p:first-child');
      const bannerCookie = Cookies.get(`banner-${bannerIdentifier}`);
      HeaderTextParagraph.prepend(HeaderBannerTitle);
      HeaderBannerTitle.style.display = 'inline-block';

      if (bannerCookie) {
        return;
      }

      headerBanner.classList.add('enabled');
      closeBanner.addEventListener('click', () => {
        headerBanner.classList.remove('enabled');
        Cookies.set(`banner-${bannerIdentifier}`, 'disabled', {
          expires: 365
        });
      });
    }
  }

}, _temp);
MainHeader = __decorate([Component], MainHeader);
export default MainHeader;