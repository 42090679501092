import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Accordion Mobile
 */

import { Component } from 'vue-property-decorator';
import AccordionComponent from '@script/mixins/AccordionComponent';
import { EVENTS } from '@script/config/constant';
let MainFooter = (_temp = class MainFooter extends AccordionComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "options", {
      multiple: true
    });
  }

  /**
   * Mounted
   */
  mounted() {
    this.destroyAccordion();
    this.attachEventHandlers();
  }
  /**
   * Attach event handlers
   */


  attachEventHandlers() {
    this.$events.$on(EVENTS.WINDOW_RESIZE, this.destroyAccordion);
  }

  destroyAccordion() {
    if (window.innerWidth > 991) {
      this.accordionComponent.$destroy();
    } else {
      this.createAccordion();
    }
  }

}, _temp);
MainFooter = __decorate([Component], MainFooter);
export default MainFooter;