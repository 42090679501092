import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Events from '@script/utils/events';
let DefaultComponent = (_temp = class DefaultComponent extends Vue {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "$events", void 0);
  }

  created() {
    this.$events = Events;
  }

  mounted() {// code
  }

  updated() {// code
  }

}, _temp);
DefaultComponent = __decorate([Component], DefaultComponent);
export default DefaultComponent;