import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";
import JobOffers from '@script/components/JobOffers';
export default class JobOffersForm {
  static init() {
    JobOffersForm.$wrapper = document.querySelector('.job-apply-form--wrapper'); // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    const {
      wpcf7
    } = window;

    if (wpcf7 && JobOffersForm.$wrapper) {
      const mailEvents = ['wpcf7invalid', 'wpcf7mailfailed'];
      const form = JobOffersForm.$wrapper.querySelector('.wpcf7-form');

      if (form) {
        const button = form === null || form === void 0 ? void 0 : form.querySelector('.cta');
        JobOffersForm.$howYouHearAboutUs = JobOffersForm.$wrapper.querySelector('[name="how-you-hear-about-us"]');
        JobOffersForm.$preciseGroup = JobOffersForm.$wrapper.querySelector('[data-id="precise-group"]');
        JobOffersForm.$howYouHearAboutUs = JobOffersForm.$wrapper.querySelector('[name="how-you-hear-about-us"]');
        JobOffersForm.$preciseGroup = JobOffersForm.$wrapper.querySelector('[data-id="precise-group"]');
        /**
         * Initialize contact forms
         */

        wpcf7.init(form);
        /**
         * Submit button hook
         */

        button === null || button === void 0 ? void 0 : button.addEventListener('click', () => {
          form === null || form === void 0 ? void 0 : form.classList.add('doing-submission');
        });
        mailEvents.forEach(fn => {
          document.addEventListener(fn, () => {
            const form = JobOffersForm.$wrapper.querySelector('.job-apply-form');

            if (form instanceof HTMLElement) {
              JobOffersForm.scrollTo(form);
            }
          }, false);
        });
        /**
         * Contact form sumitted
         */

        document.addEventListener('wpcf7submit', () => {
          form === null || form === void 0 ? void 0 : form.classList.remove('doing-submission');
        }, false);
        /**
         * Contact form sumitted
         */

        document.addEventListener('wpcf7mailsent', JobOffersForm.initFromStatus, false);
        JobOffersForm.initPrecise();
        JobOffersForm.initFormOpen();
        JobOffersForm.initFromStatus();
      }
    }
  }

  static initPrecise() {
    JobOffersForm.$howYouHearAboutUs.addEventListener('change', () => {
      // console.log('How did you hear about us', JobOffersForm.$howYouHearAboutUs.value)
      if (JobOffersForm.$howYouHearAboutUs.value.toLowerCase() === 'other') {
        JobOffersForm.$preciseGroup.classList.add('visible');
      } else {
        JobOffersForm.$preciseGroup.classList.remove('visible');
      }
    });
  }

  static initFormOpen() {
    // console.log('location:', location.href)
    if (location.href.indexOf('#wpcf7') !== -1) {
      JobOffers.openForm();
    }
  }

  static initFromStatus() {
    var _JobOffersForm$$wrapp;

    if (((_JobOffersForm$$wrapp = JobOffersForm.$wrapper.querySelector('form')) === null || _JobOffersForm$$wrapp === void 0 ? void 0 : _JobOffersForm$$wrapp.getAttribute('data-status')) === 'sent') {
      var _JobOffersForm$$wrapp2;

      (_JobOffersForm$$wrapp2 = JobOffersForm.$wrapper.querySelector('.job-apply-form')) === null || _JobOffersForm$$wrapp2 === void 0 ? void 0 : _JobOffersForm$$wrapp2.classList.add('feedback');
    }
  }
  /**
   * Smooth scroll
   */


  static scrollTo(target) {
    JobOffersForm.$wrapper.scroll({
      top: target.offsetTop,
      behavior: 'smooth'
    });
  }

}

_defineProperty(JobOffersForm, "$wrapper", void 0);

_defineProperty(JobOffersForm, "$howYouHearAboutUs", void 0);

_defineProperty(JobOffersForm, "$preciseGroup", void 0);