/*
 * Copyright © 2021 - яαvoroηα
 *
 * @project Sage
 * @file application.ts
 * @author ravorona
 */
import '@style/fonts.scss';
import '@style/critical.scss';
import '@style/application.scss';
import '@script/utils/register-svg';
import Main from '@script/components/Main/Main';
/**
 * Application scripts
 */

const checkReadyState = () => {
  const APP = document.getElementById('fm-logistics');

  if (document.readyState === 'complete' && APP instanceof HTMLElement) {
    window.application = new Main({
      el: APP
    });
    /**
     * Start app
     */

    window.application.setup();
  }
};
/**
 * Add document state event listener
 */


document.addEventListener('readystatechange', checkReadyState);
/**
 * Check state on start
 */

checkReadyState();