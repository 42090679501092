import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
let LocationView = (_temp = class LocationView extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "notScheduledChar", ' - ');

    _defineProperty(this, "hoursOfOpeningLabel", Vue.$mapTranslations.hoursOfOpening);

    _defineProperty(this, "servicesLabel", Vue.$mapTranslations.servicesOffered);

    _defineProperty(this, "contactUsLabel", Vue.$mapTranslations.contactUs);

    _defineProperty(this, "location", void 0);
  }

  get schedule() {
    if (this.location) {
      if (this.location.openingDays && this.location.closingTime && this.location.openingDays && this.location.openingDays.length) {
        return this.location.daysLabel.slice(0, 6).map(day => {
          const isOpen = this.location.openingDays.includes(day);
          const openingTime = this.location.openingTime ? this.location.openingTime.replace(' ', '<br />') : this.notScheduledChar;
          const closingTime = this.location.closingTime ? this.location.closingTime.replace(' ', '<br />') : this.notScheduledChar;
          return {
            day,
            isOpen,
            openingTime: isOpen ? openingTime : this.notScheduledChar,
            closingTime: isOpen ? closingTime : this.notScheduledChar
          };
        });
      }
    }

    return null;
  }

  isValidEmail(mail) {
    console.log('location -> ', this.location); // eslint-disable-next-line no-useless-escape

    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(mail);
  }

  gotoMapDirection() {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${[this.location.location.lat, this.location.location.long]}`;
    window.open(url, '_blank');
  }

}, _temp);

__decorate([Prop()], LocationView.prototype, "location", void 0);

LocationView = __decorate([Component], LocationView);
export default LocationView;