import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Block offers
 */

import { Component } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
import UIkit from 'uikit';
import { EVENTS } from '@script/config/constant';
let SwitcherComponent = (_temp = class SwitcherComponent extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "switcher", void 0);
  }

  /**
   * Components mounted
   */
  mounted() {
    this.createSwitcher();
    this.attachEventsHandlers();
  }
  /**
   * Attach event Handlers
   */


  attachEventsHandlers() {
    this.$events.$on(EVENTS.SELECT_UPDATE_OPTION, this.toggleSwitcher);
  }
  /**
   * Create swicther Tab
   */


  createSwitcher() {
    const options = {
      animation: 'uk-animation-fade'
    };
    const tabs = this.$el.querySelector('.tabs-title');
    this.switcher = UIkit.switcher(tabs, options);
  }
  /**
   * Toggle swicher
   * @param index
   */


  toggleSwitcher(index) {
    this.switcher.show(index);
  }

}, _temp);
SwitcherComponent = __decorate([Component], SwitcherComponent);
export default SwitcherComponent;