var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-select-wrapper" }, [
    _c(
      "div",
      {
        class: [{ open: _vm.isOpen }, "custom-select"],
        on: {
          click: function($event) {
            return _vm.toggleOption()
          }
        }
      },
      [
        _c("div", { staticClass: "custom-select__trigger" }, [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.selectedOption) } }),
          _c("div", { staticClass: "arrow" })
        ]),
        _c(
          "div",
          { staticClass: "custom-options" },
          _vm._l(_vm.options, function(option, index) {
            return _c("span", {
              key: index,
              class: [
                _vm.selected === index ? "selected" : "",
                "custom-option"
              ],
              domProps: { innerHTML: _vm._s(option) },
              on: {
                click: function($event) {
                  return _vm.onSelectOption(index)
                }
              }
            })
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }