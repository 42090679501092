export default class BlockSimplePresentation {
  static init() {
    const blockSimplePresentationAll = document.querySelectorAll('.block-simple-presentation');
    blockSimplePresentationAll.forEach(function (block) {
      const seeMoreBtn = block.querySelector('.see-more-btn');
      seeMoreBtn === null || seeMoreBtn === void 0 ? void 0 : seeMoreBtn.addEventListener('click', function () {
        var _block$querySelector;

        (_block$querySelector = block.querySelector('.editor')) === null || _block$querySelector === void 0 ? void 0 : _block$querySelector.classList.toggle('closed');
      });
    });
  }

}