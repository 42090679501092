import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
import Countries from '@script/components/Calculator/Countries.vue';
import City from '@script/components/Calculator/City.vue';
import { EVENTS } from '@/scripts/config/constant';
import gsap from 'gsap';
let CalculatorForm = (_temp = class CalculatorForm extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "quantity", false);

    _defineProperty(this, "transportChoices", []);

    _defineProperty(this, "departureType", '');

    _defineProperty(this, "destinationType", '');

    _defineProperty(this, "departureCountry", false);

    _defineProperty(this, "destinationCountry", false);

    _defineProperty(this, "departureCity", false);

    _defineProperty(this, "destinationCity", false);

    _defineProperty(this, "errors", []);

    _defineProperty(this, "lang", void 0);
  }

  /**
   * Computed
   */
  get departureTypeParse() {
    return this.departureType ? JSON.parse(this.departureType) : '';
  }

  get destinationTypeParse() {
    return this.destinationType ? JSON.parse(this.destinationType) : '';
  }
  /**
   * Mounted
   */


  mounted() {
    this.attachEventHandlers();
  }
  /**
   * Attach event handler
   */


  attachEventHandlers() {
    this.$events.$on(EVENTS.CALCULATOR_FORM_UPDATE_COUNTRY_OPTIONS, country => {
      if (country.id === 'field-departure-country') {
        this.departureCountry = country;
      } else {
        this.destinationCountry = country;
      }
    });
    this.$events.$on(EVENTS.CALCULATOR_FORM_UPDATE_CITY, city => {
      if (city.id === 'field-departure-city') {
        this.departureCity = city;
      } else {
        this.destinationCity = city;
      }
    });
  }

  validateForm(event) {
    const FORM = event.target;
    const ID = [1, 2];
    this.errors = [];

    if (!this.quantity) {
      this.errors.push('quantity');
    }

    if (!this.transportChoices.length) {
      this.errors.push('transport_choices');
    }

    if (!this.departureTypeParse) {
      this.errors.push('departure_type');
    }

    if (!this.destinationTypeParse) {
      this.errors.push('destination_type');
    }

    if (ID.includes(this.departureTypeParse.value) && !this.departureCountry) {
      this.errors.push('departure_country');
    }

    if (ID.includes(this.destinationTypeParse.value) && !this.destinationCountry) {
      this.errors.push('destination_country');
    }

    if (!this.departureCity) {
      this.errors.push('departure_city');
    }

    if (!this.destinationCity) {
      this.errors.push('destination_city');
    }

    if (!this.errors.length) {
      return true;
    }

    this.scrollTo(FORM);
    event.preventDefault();
  }

  scrollTo(target) {
    const OFFSET = 20;
    const TIMELINE = gsap.timeline({
      paused: true
    });
    TIMELINE.to(window, {
      scrollTo: {
        y: target.offsetTop,
        offsetY: OFFSET
      },
      duration: 1,
      ease: 'power4.inout'
    });
    TIMELINE.play();
  }

}, _temp);

__decorate([Prop()], CalculatorForm.prototype, "lang", void 0);

CalculatorForm = __decorate([Component({
  components: {
    Countries,
    City
  }
})], CalculatorForm);
export default CalculatorForm;