import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
import Events, { LOCATIONS_EVENTS } from '@script/components/BlockLocations/BlockLocationsEvents';
import MapSelectbox from '@script/components/BlockLocations/MapSelectbox.vue';
let TitlesWrapper = (_temp = class TitlesWrapper extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "currentPage", 0);

    _defineProperty(this, "currentLocation", null);

    _defineProperty(this, "selectBoxCountries", []);

    _defineProperty(this, "selectedCountryLabel", Vue.$mapTranslations.chooseCountry);

    _defineProperty(this, "baseTitle", Vue.$mapTranslations.blockTitle);

    _defineProperty(this, "subtitle", Vue.$mapTranslations.blockSubtitle);

    _defineProperty(this, "btnbackLabel", Vue.$mapTranslations.back);

    _defineProperty(this, "locationName", null);

    _defineProperty(this, "countries", void 0);

    _defineProperty(this, "ismobile", void 0);
  }

  onCountriesChange(val) {
    this.selectBoxCountries = val.map(country => country);
  } // Select box handler


  onSelectCountry(country) {
    Events.dispatchEvent(LOCATIONS_EVENTS.ON_SELECT_COUNTRY, country);
  } // Event handler


  onCountryUpdated(country) {
    this.currentPage = 1;
    this.selectedCountryLabel = this.subtitle = country.label;
  } // Location selection handler


  onSelectLocation(loc) {
    this.currentLocation = loc;
    this.currentPage = 2;
  } // Handle back button


  onBack() {
    Events.dispatchEvent(LOCATIONS_EVENTS.ON_CHANGE_PAGE, 1);
  } // Handle page change


  onChangePage(pageNumber) {
    console.log('selectedCountryLabel > ', this.selectedCountryLabel);
    this.currentPage = pageNumber;
  } // Listen hash existence/change
  // hashValue is in url encoded format


  onHashChange(hashValue) {
    // Look hash if amongs countries
    const country = this.countries.find(country => {
      return country.slug === hashValue.toLowerCase();
    });

    if (country) {
      Events.dispatchEvent(LOCATIONS_EVENTS.ON_SELECT_COUNTRY, country);
    }
  }

  mounted() {
    if (this.countries) {
      this.onCountriesChange(this.countries);
    }

    Events.$on(LOCATIONS_EVENTS.ON_SELECT_COUNTRY, this.onCountryUpdated);
    Events.$on(LOCATIONS_EVENTS.ON_SELECT_LOCATION, this.onSelectLocation);
    Events.$on(LOCATIONS_EVENTS.ON_CHANGE_PAGE, this.onChangePage);
    Events.$on(LOCATIONS_EVENTS.ON_LOCATION_HASH_CHANGE, this.onHashChange);
    setTimeout(() => {
      Events.initCountryHashListener();
    }, 250);
  }

  beforeUnmount() {
    Events.$off(LOCATIONS_EVENTS.ON_SELECT_COUNTRY, this.onCountryUpdated);
  }

}, _temp);

__decorate([Prop()], TitlesWrapper.prototype, "countries", void 0);

__decorate([Prop()], TitlesWrapper.prototype, "ismobile", void 0);

__decorate([Watch('countries')], TitlesWrapper.prototype, "onCountriesChange", null);

TitlesWrapper = __decorate([Component({
  components: {
    MapSelectbox
  }
})], TitlesWrapper);
export default TitlesWrapper;