var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-select-wrapper" }, [
    _c(
      "div",
      {
        class: [{ open: _vm.isOpen }, "custom-select"],
        on: {
          click: function($event) {
            return _vm.toggleOption()
          }
        }
      },
      [
        _c("div", { staticClass: "custom-select__trigger" }, [
          _c("span", [
            _vm._v(
              _vm._s(_vm.selectedOption ? _vm.selectedOption.label : "") + " "
            )
          ]),
          _c("div", { staticClass: "arrow" }, [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "16px",
                  height: "16px",
                  viewBox: "0 0 16 16"
                }
              },
              [
                _c("path", {
                  attrs: {
                    stroke: "currentColor",
                    "stroke-width": "1.5",
                    "stroke-linejoin": "miter",
                    "stroke-linecap": "butt",
                    "stroke-miterlimit": "4",
                    fill: "none",
                    d: "M 1 6.55L 8 13.55 15 6.5"
                  }
                })
              ]
            )
          ])
        ]),
        _vm.options.length
          ? _c(
              "div",
              { staticClass: "custom-options" },
              _vm._l(_vm.options, function(option, index) {
                return _c(
                  "span",
                  {
                    key: index,
                    class: [
                      option &&
                      _vm.selectedOption &&
                      _vm.selectedOption.id === option.id
                        ? "selected"
                        : "",
                      "custom-option"
                    ],
                    on: {
                      click: function($event) {
                        return _vm.handleSelection(option)
                      }
                    }
                  },
                  [_vm._v(_vm._s(option.label))]
                )
              }),
              0
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }