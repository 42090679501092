import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Hero
 */

import { Component, Prop, Watch } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
import gsap from 'gsap';
let Hero = (_temp = class Hero extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "expanded", false);

    _defineProperty(this, "readMoreTimeline", void 0);

    _defineProperty(this, "hidden", false);

    _defineProperty(this, "defaultHeight", void 0);
  }

  /**
   * Mounted
   */
  mounted() {
    this.checkHeight();
    this.initTimeline();
  }

  switchExpanded(expanded) {
    var _this$readMoreTimelin;

    (_this$readMoreTimelin = this.readMoreTimeline) === null || _this$readMoreTimelin === void 0 ? void 0 : _this$readMoreTimelin.duration(1).reversed(!expanded);
  }

  initTimeline() {
    const toggleElement = this.$refs.readmore;
    this.readMoreTimeline = gsap.timeline({
      paused: true,
      yoyo: true,
      onCompleteScope: this,
      onReverseCompleteScope: this
    });

    if (toggleElement instanceof HTMLElement) {
      this.readMoreTimeline.from(toggleElement, {
        height: this.defaultHeight,
        duration: 1,
        ease: 'power4.out'
      }).reverse();
    }
  }

  checkHeight() {
    const toggleElement = this.$refs.readmore;

    if (toggleElement instanceof HTMLElement) {
      this.hidden = toggleElement.clientHeight <= this.defaultHeight;
    }
  }

}, _temp);

__decorate([Prop({
  default: 640
})], Hero.prototype, "defaultHeight", void 0);

__decorate([Watch('expanded')], Hero.prototype, "switchExpanded", null);

Hero = __decorate([Component], Hero);
export default Hero;