import { render, staticRenderFns } from "./BlockBusinessCases.vue?vue&type=template&id=1747d8a1&"
import script from "./BlockBusinessCases.ts?vue&type=script&lang=ts&"
export * from "./BlockBusinessCases.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1747d8a1')) {
      api.createRecord('1747d8a1', component.options)
    } else {
      api.reload('1747d8a1', component.options)
    }
    module.hot.accept("./BlockBusinessCases.vue?vue&type=template&id=1747d8a1&", function () {
      api.rerender('1747d8a1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/scripts/components/BlockBusinessCases/BlockBusinessCases.vue"
export default component.exports