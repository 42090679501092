import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";
import AjaxUtils from '@script/utils/ajax-utils';
export default class Single {
  static init() {
    if (document.querySelector('.single-post')) {
      Single.$containers = document.querySelectorAll('.js-relevant-content');
      Single.attachEventListener();
    }
  }

  static attachEventListener() {
    /* eslint-disable */

    /* @ts-ignore */
    const $buttons = document.querySelectorAll('[data-relevant-content-button]');
    Single.$containers.forEach($container => {
      $buttons.forEach($button => {
        $button.addEventListener('click', e => {
          const type = $button.dataset.relevantContentButton === 'like' ? 'like' : 'dislike';
          $container.classList.add('loading');
          /* @ts-ignore */

          AjaxUtils.postData(ajax.url, {
            action: 'add_relevant_content_' + type,

            /* @ts-ignore */
            post_id: ajax.post_id,

            /* @ts-ignore */
            like: ajax.like
          }).then(response => {
            if (response.success === true) {
              var _$container$parentNod;

              (_$container$parentNod = $container.parentNode) === null || _$container$parentNod === void 0 ? void 0 : _$container$parentNod.removeChild($container);
            } else {
              console.error(response.data.error_message);
              $container.classList.remove('loading');
            }
          }).catch(error => {
            var _$container$parentNod2;

            (_$container$parentNod2 = $container.parentNode) === null || _$container$parentNod2 === void 0 ? void 0 : _$container$parentNod2.removeChild($container);
          });
        });
      });
    });
    /* eslint-enable */
  }

}

_defineProperty(Single, "$containers", void 0);