import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { Vue } from 'vue-property-decorator';
export const LOCATIONS_EVENTS = {
  ON_SELECT_COUNTRY: 'on_select_country',
  ON_SELECT_LOCATION: 'on_select_location',
  ON_ACTIVITY_FILTER_CHANGE: 'on_activity_filter_change',
  ON_CHANGE_PAGE: 'on_change_page',
  ON_LOCATION_HASH_CHANGE: 'on_location_hash_change',
  ON_LOCATIONS_LIST_UPDATE: 'on_locations_list_update'
};

class EventBus extends Vue {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isMapLoaded", false);

    _defineProperty(this, "countryHashListenerInitialized", false);
  }

  static isValidEvent(evtName) {
    const values = Object.values(LOCATIONS_EVENTS);
    return values.includes(evtName);
  }

  dispatchEvent(evtType, data) {
    if (EventBus.isValidEvent(evtType)) {
      this.$emit(evtType, data);
    }
  }

  setIsMapLoaded(state) {
    this.isMapLoaded = state;
  }

  dispatchHashChange() {
    try {
      const hash = window.location.hash.split('#')[1];
      console.log('hash --> ', hash); // hash is in url encoded format

      if (this.isMapLoaded && hash) {
        this.dispatchEvent(LOCATIONS_EVENTS.ON_LOCATION_HASH_CHANGE, hash);
      }
    } catch (e) {
      console.log(e);
    }
  }

  initCountryHashListener() {
    if (!this.countryHashListenerInitialized) {
      this.countryHashListenerInitialized = true;
      window.addEventListener('hashchange', () => {
        this.dispatchHashChange();
      });
      this.dispatchHashChange();
    }
  }

}

const Events = new EventBus();
export default Events;