import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Slider Hero
 */

import { Component } from 'vue-property-decorator';
import SliderComponent from '@script/mixins/SliderComponent';
let SliderHero = (_temp = class SliderHero extends SliderComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "options", {
      slidesPerView: 'auto',
      autoplay: {
        delay: 5000
      },
      on: {
        slideChange: swiper => {
          const currentSlideIndex = swiper.realIndex;
          console.log(swiper.slides[currentSlideIndex]);
        }
      }
    });
  }

  /**
   * Attach event
   */
  attachEventsHandlers() {
    let active = 0;
    let activeMobile = 0;
    const SLIDE_TITLES = this.$el.querySelectorAll('.swiper-nav .item');
    const SLIDE_TITLES_MOBILE = this.$el.querySelectorAll('.swiper-nav-mobile .item');

    if (SLIDE_TITLES_MOBILE.length) {
      SLIDE_TITLES_MOBILE.item(0).classList.add('active');
      this.sliderComponent.on('activeIndexChange', event => {
        const {
          activeIndex
        } = event;
        SLIDE_TITLES_MOBILE.forEach(() => {
          SLIDE_TITLES_MOBILE.item(activeMobile).classList.remove('active');
          SLIDE_TITLES_MOBILE.item(activeIndex).classList.add('active');
          activeMobile = activeIndex;
        });
      });
      SLIDE_TITLES_MOBILE.forEach(link => {
        link.addEventListener('mouseover', () => {
          this.stopAutoplay();
          SLIDE_TITLES_MOBILE.forEach(link => {
            if (!link.classList.contains('paused')) {
              link.classList.add('paused');
            }
          });
        });
      });
    }

    if (SLIDE_TITLES.length) {
      SLIDE_TITLES.item(0).classList.add('active');
      this.sliderComponent.on('activeIndexChange', event => {
        const {
          activeIndex
        } = event;
        SLIDE_TITLES.forEach(() => {
          SLIDE_TITLES.item(active).classList.remove('active');
          SLIDE_TITLES.item(activeIndex).classList.add('active');
          active = activeIndex;
        });
      });
      SLIDE_TITLES.forEach((link, index) => {
        link.addEventListener('click', event => {
          event.preventDefault();
          this.slideTo(index);
        });
        link.addEventListener('mouseover', () => {
          this.stopAutoplay();
          SLIDE_TITLES.forEach(link => {
            if (!link.classList.contains('paused')) {
              link.classList.add('paused');
            }
          });
        });
        link.style.flexBasis = 100 / SLIDE_TITLES.length + '%';
      });
    }
  }

}, _temp);
SliderHero = __decorate([Component], SliderHero);
export default SliderHero;