import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Header
 */

import { Component, Prop, Watch } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
import gsap from 'gsap';
let Glossary = (_temp = class Glossary extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "term", '');

    _defineProperty(this, "active", -1);

    _defineProperty(this, "disabled", []);

    _defineProperty(this, "glossary", []);

    _defineProperty(this, "isScrolling", null);

    _defineProperty(this, "alphabet", void 0);

    _defineProperty(this, "terms", void 0);

    _defineProperty(this, "lang", void 0);
  }

  /**
   * Mounted
   */
  mounted() {
    this.attachEventHandler();
  }
  /**
   * Attach event handler
   */


  attachEventHandler() {
    this.alphabet.forEach((letter, index) => {
      const TERMS = this.sortTerms(this.filterTerm(letter));
      const KEY = letter === '0-9' ? 'num' : letter;

      if (TERMS.length) {
        this.glossary = { ...this.glossary,
          [KEY]: TERMS
        };
        this.disabled.push(index);
      }
    });
    this.$nextTick(() => {
      this.scrollSpyTerms();
      window.addEventListener('scroll', () => {
        window.clearTimeout(this.isScrolling);
        this.isScrolling = setTimeout(() => {
          this.scrollSpyTerms();
        }, 66);
      }, false);
    });
  }

  getVisibleHeightPx(element, viewportHeight) {
    const rect = element.getBoundingClientRect();
    const height = rect.bottom - rect.top;
    const visible = {
      top: rect.top >= 0 && rect.top < viewportHeight,
      bottom: rect.bottom > 0 && rect.bottom < viewportHeight
    };
    let visiblePx = 0;

    if (visible.top && visible.bottom) {
      visiblePx = height;
    } else if (visible.top) {
      visiblePx = viewportHeight - rect.top;
    } else if (visible.bottom) {
      visiblePx = rect.bottom;
    } else if (height > viewportHeight && rect.top < 0) {
      const absTop = Math.abs(rect.top);

      if (absTop < height) {
        visiblePx = height - absTop;
      }
    }

    return visiblePx;
  }

  scrollSpyTerms() {
    const clientHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const hitBoxTop = clientHeight * 0.3;
    const hitBoxBottom = clientHeight * 0.8;
    let mostVisibleIndex = -1;
    this.active = -1;
    this.alphabet.forEach((letter, index) => {
      const KEY = letter === '0-9' ? 'num' : letter;
      const TERM = document.getElementById(`${KEY}-term`);

      if (TERM) {
        const thisTop = TERM.offsetTop - scrollTop;
        const rect = TERM.getBoundingClientRect();
        const thisBottom = thisTop + rect.height;

        if (mostVisibleIndex === -1) {
          if (thisTop < hitBoxTop) {
            if (thisBottom >= hitBoxTop) {
              mostVisibleIndex = index;
            }
          }

          if (thisTop >= hitBoxTop) {
            if (thisTop <= hitBoxBottom) {
              mostVisibleIndex = index;
            }
          }
        }
      }
    });
    this.active = mostVisibleIndex;
  }
  /**
   * Set term
   * @param term term to activate
   */


  set(term, index) {
    this.term = term === '0-9' ? 'num' : term;
    console.log(this.active, index);
    this.active = index;
  }
  /**
   * Check if glossary term is disable
   * @param value alphabet value
   * @returns boolean
   */


  isDisabled(value) {
    return this.disabled.indexOf(value) === -1;
  }
  /**
   * Term event
   * @returns void
   */


  termEvent(current) {
    const TIMELINE = gsap.timeline({
      paused: true
    });
    const TERM = this.$refs.glossary.querySelector(`#${current}-term`);
    const OFFSET = 130;

    if (TERM instanceof HTMLElement) {
      TIMELINE.to(window, {
        scrollTo: {
          y: TERM.offsetTop,
          offsetY: OFFSET
        },
        duration: 1,
        ease: 'power4.inout'
      });
      TIMELINE.play();
    }
  }
  /**
   * Sort
   */


  sortTerms(terms) {
    terms.sort((a, b) => a.title.localeCompare(b.title));
    return terms;
  }
  /**
   * Filter
   */


  filterTerm(term) {
    return this.terms.filter(({
      title
    }) => {
      return term === '' || term === '0-9' && !isNaN(Number(title[0])) || title[0].localeCompare(term, this.lang, {
        sensitivity: 'base'
      }) === 0;
    });
  }

}, _temp);

__decorate([Prop()], Glossary.prototype, "alphabet", void 0);

__decorate([Prop()], Glossary.prototype, "terms", void 0);

__decorate([Prop()], Glossary.prototype, "lang", void 0);

__decorate([Watch('term')], Glossary.prototype, "termEvent", null);

Glossary = __decorate([Component], Glossary);
export default Glossary;