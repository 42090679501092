var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "29",
        height: "29",
        viewBox: "0 0 29 29",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("circle", {
        attrs: {
          opacity: "0.2",
          cx: "14.5587",
          cy: "14.2827",
          r: "10.4557",
          transform: "rotate(60 14.5587 14.2827)",
          fill: "#00D425"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "14.5588",
          cy: "14.2828",
          r: "3.48523",
          transform: "rotate(60 14.5588 14.2828)",
          fill: "#00D425"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }