var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "locations-list-countries" }, [
    _c("div", { staticClass: "inner-wrapper" }, [
      _vm.countries.length
        ? _c(
            "ul",
            _vm._l(_vm.countries, function(country) {
              return _c("li", { key: country.id }, [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.onSelect(country)
                      }
                    }
                  },
                  [_vm._v(_vm._s(country.label))]
                )
              ])
            }),
            0
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }