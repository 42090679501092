import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
import request from 'axios';
import { EVENTS } from '@script/config/constant'; // import UIkit from 'uikit'

let Archives = (_temp = class Archives extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "message", void 0);

    _defineProperty(this, "loading", false);

    _defineProperty(this, "cancelToken", void 0);

    _defineProperty(this, "requestState", void 0);

    _defineProperty(this, "activity", void 0);

    _defineProperty(this, "solution", void 0);

    _defineProperty(this, "isReset", false);
  }

  /**
   * Component created handler
   * @method created
   */
  created() {
    this.cancelToken = request.CancelToken;
    this.requestState = false;
    this.message = '';
  }
  /**
   * Component mounted handler
   * @method mounted
   */


  mounted() {
    this.attachEventHandlers();
  }
  /**
   * Attach events handler
   */


  attachEventHandlers() {
    const activity = this.$refs.activity;
    const solution = this.$refs.solution;
    activity.addEventListener('change', () => {
      const index = activity.selectedIndex;
      this.activity = activity.options[index].value;
      this.$events.$emit(EVENTS.CASE_STUDIES_UPDATE_OPTIONS);
    });
    solution.addEventListener('change', () => {
      const index = solution.selectedIndex;
      this.solution = solution.options[index].value;
      this.$events.$emit(EVENTS.CASE_STUDIES_UPDATE_OPTIONS);
    });
    this.$events.$on(EVENTS.CASE_STUDIES_UPDATE_OPTIONS, this.filter);
  }

  reset() {
    const activity = this.$refs.activity;
    const solution = this.$refs.solution;
    activity.selectedIndex = 0;
    solution.selectedIndex = 0;
    this.activity = '';
    this.solution = '';
    this.isReset = true;
    this.$events.$emit(EVENTS.CASE_STUDIES_UPDATE_OPTIONS);
  }
  /**
   * Ajax filter
   */


  filter() {
    const params = new FormData();
    const message = 'Unable to retrieve case studies. Try again later.'; // Loading start

    this.loading = true; // Clear message

    this.message = ''; // Fill request params
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    params.append('case-studies', ajax.case_studies);
    params.append('action', 'get_case_studies');

    if (this.activity) {
      params.append('activity', this.activity);
    }

    if (this.solution) {
      params.append('solution', this.solution);
    } // Cancel concurrent request


    if (this.requestState) {
      this.requestState.cancel('Case studies retrieval canceled.');
    } // Register current request


    this.requestState = this.cancelToken.source(); // Perform request

    request.post( // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ajax.url, params, {
      cancelToken: this.requestState.token
    }).then(response => {
      // Loading end
      this.loading = false;

      if (response && response.status === 200 && response.data) {
        const parser = new DOMParser();
        const income = parser.parseFromString(response.data.case_studies, 'text/html');
        const caseStudies = income.querySelector('.case-studies-list');
        console.log(caseStudies); // Insert element

        if (this.$refs.list instanceof HTMLElement) {
          if (this.isReset) {
            this.$refs.list.innerHTML = response.data.highlight;
            this.$refs.list.appendChild(caseStudies);
            this.isReset = false;
          } else {
            this.$refs.list.innerHTML = response.data.case_studies;
          }
        } // Show new elements


        this.showIncomingElements();
      } else {
        // Throw error
        throw new Error('Incorrect response data');
      }
    }).catch(error => {
      console.error(error.message); // Loading end

      this.loading = false; // Update message

      this.message = message;
    });
  }

  showIncomingElements() {// code
  }

}, _temp);
Archives = __decorate([Component], Archives);
export default Archives;