import "core-js/modules/web.dom-collections.iterator";

/*
 * Copyright © 2021 - яαvoroηα
 *
 * @project Sage
 * @file register-svg.ts
 * @author ravorona
 */
const requireSvg = (() => {
  const loadSvg = r => {
    r.keys().forEach(r);
  };

  loadSvg(require.context('../../svg/', true, /\.svg$/));
})();

export default requireSvg;