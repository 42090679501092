import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import DefaultComponent from '@script/mixins/DefaultComponent';
import { EVENTS } from '@/scripts/config/constant';
import request from 'axios';
let City = (_temp = class City extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "search", '');

    _defineProperty(this, "result", false);

    _defineProperty(this, "loading", false);

    _defineProperty(this, "message", '');

    _defineProperty(this, "value", '');

    _defineProperty(this, "abort", void 0);

    _defineProperty(this, "lang", void 0);

    _defineProperty(this, "defaultName", void 0);

    _defineProperty(this, "msg", void 0);

    _defineProperty(this, "isRequired", void 0);

    _defineProperty(this, "location", void 0);

    _defineProperty(this, "labels", void 0);

    _defineProperty(this, "country", void 0);
  }

  /**
   * Mounted
   */
  mounted() {
    this.attachEventHandlers();
  }
  /**
   * Attach event
   */


  attachEventHandlers() {
    document.addEventListener('click', () => {
      if (!this.value) {
        this.result = false; // this.onValueChange()
      }
    });
  }
  /**
   * Mounted
   */


  get label() {
    return this.labels[0]; // return this.location && this.location.value === 2 ? this.labels[1] : this.labels[0]
  }
  /**
   * Computed
   */


  get isDisable() {
    const ID = [1, 2];
    return this.location && ID.includes(this.location.value) && !(this.country && Object.keys(this.country).length);
  }
  /**
   * Set value
   * @param value
   */


  setValue(value) {
    this.value = value;
    this.result = false;
    this.search = value;
    this.onValueChange();
  }
  /**
   * Reset value when location change
   */


  onLocationChange() {
    this.setValue('');
  }
  /**
   * Reset value when country change
   */


  onCountryChange() {
    this.setValue('');
  }
  /**
   * Value change event
   */


  onValueChange() {
    if (this.value) {
      this.$events.$emit(EVENTS.CALCULATOR_FORM_UPDATE_CITY, {
        id: this.defaultName,
        value: this.value
      });
    }
  }
  /**
   * Load
   */


  load() {
    const term = this.search;
    const PARAMS = new FormData();

    if (term.length > 2) {
      // Loading start
      this.loading = true; // Clear message

      this.message = '';
      this.value = ''; // Fill request params
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore

      PARAMS.append('search_city', ajax.search_city);
      PARAMS.append('action', 'get_search_city');
      PARAMS.append('location', `${this.location.value}`);
      PARAMS.append('country', `${this.country.value}`);
      PARAMS.append('term', `${term}`); // Cancel concurrent request

      if (this.abort) {
        this.abort.abort();
        console.log('City retrive aborted');
      }

      this.abort = new AbortController(); // Perform request

      request.post( // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ajax.url, PARAMS, {
        signal: this.abort.signal
      }).then(response => {
        // Loading end
        this.loading = false;

        if (response && response.status === 200 && response.data) {
          const LIST = response.data;

          if (LIST && Object.keys(LIST).length) {
            this.result = response.data;
          } else {
            this.result = false;
            this.message = this.msg;
          }
        } else {
          // Throw error
          throw new Error('Incorrect response data');
        }
      }).catch(error => {
        console.error(error.message);

        if (!request.isCancel(error)) {
          this.loading = false;
          this.message = this.msg;
        }
      });
    }
  }

}, _temp);

__decorate([Prop()], City.prototype, "lang", void 0);

__decorate([Prop()], City.prototype, "defaultName", void 0);

__decorate([Prop()], City.prototype, "msg", void 0);

__decorate([Prop()], City.prototype, "isRequired", void 0);

__decorate([Prop()], City.prototype, "location", void 0);

__decorate([Prop()], City.prototype, "labels", void 0);

__decorate([Prop()], City.prototype, "country", void 0);

__decorate([Watch('location')], City.prototype, "onLocationChange", null);

__decorate([Watch('country')], City.prototype, "onCountryChange", null);

City = __decorate([Component], City);
export default City;