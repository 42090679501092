import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Accordion
 */

import { Component } from 'vue-property-decorator';
import UIkit from 'uikit';
import DefaultComponent from '@script/mixins/DefaultComponent';
let AccordionComponent = (_temp = class AccordionComponent extends DefaultComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "accordionComponent", void 0);

    _defineProperty(this, "options", void 0);
  }

  /**
   * Mounted
   */
  mounted() {
    this.createAccordion();
  }

  createAccordion() {
    this.accordionComponent = UIkit.accordion(this.$el, this.options);
  }

}, _temp);
AccordionComponent = __decorate([Component], AccordionComponent);
export default AccordionComponent;