export default class BehaviorLinks {
  static init() {
    const links = document.querySelectorAll('[data-behavior=\'link\']');
    links.forEach(function (element) {
      element.addEventListener('click', event => {
        event.preventDefault();
        const target = element.getAttribute('data-link-target');
        const href = element.getAttribute('data-link-href');

        if (target === '_blank') {
          window.open(href);
        } else {
          window.location = href;
        }
      });
    });
  }

}