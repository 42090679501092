import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "/opt/atlassian/pipelines/agent/build/web/app/themes/fmlogistics/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _temp;

import { __decorate } from "tslib";
/**
 * Slider social feeds
 */

import { Component } from 'vue-property-decorator';
import SliderComponent from '@script/mixins/SliderComponent';
let SliderSocialFeeds = (_temp = class SliderSocialFeeds extends SliderComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "options", {
      slidesPerView: 1,
      breakpoints: {
        768: {
          slidesPerView: 2
        },
        1280: {
          slidesPerView: 3
        }
      }
    });
  }

}, _temp);
SliderSocialFeeds = __decorate([Component], SliderSocialFeeds);
export default SliderSocialFeeds;